import { GridData } from '@/components/globals/data';
import { GridMapleTable } from '@/components/ui/grid-maple-table';
import { ViewCardList } from '@/components/ui/view-card-list';

interface RegistrationListProps {}

const RegistrationList: React.FC<RegistrationListProps> = () => {
  return (
    <ViewCardList title="Registrations">
      <GridMapleTable
        entity="registrations"
        gridMapper={GridData.registration.mapper}
        gridColDef={GridData.registration.columns}
        filterSearchColDef={['firstName', 'lastName', 'email']}
        hideMenuButtons={true}
      />
    </ViewCardList>
  );
};

export default RegistrationList;
