import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import { ButtonDeleteWithDialog } from '@/components/ui/button-delete-with-dialog';
import { ButtonFormSettings } from '@/components/ui/button-form-settings';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface ActivityEditProps {}

const FormSchema = z.object({
  title: z.string().max(500),
  description: z.string().max(2500),
  activityTypeCode: z.number(),
  priorityCode: z.number().optional(),
  date: z.string().optional(),
  location: z.string().optional(),
  createdByName: z.string().optional(),
  modifiedByName: z.string().optional(),
});

const ActivityEdit: React.FC<ActivityEditProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activityType, setActivityType] = useState('');
  const [activityInfo, setActivityInfo] = useState<ActivityModel>();
  let { activityId } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileCtx);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: '',
      description: '',
      date: moment(new Date()).format('YYYY-MM-DD'),
      location: '',
      createdByName: activityInfo?.createdByName,
      modifiedByName: activityInfo?.modifiedByName,
    },
  });

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/activities/${activityId}`)
        .then((response: AxiosResponse<ActivityModel>) => {
          if (response.status === 200) {
            setActivityType(response.data.activityTypeCode.toString());
            setActivityInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...activityInfo,
    });
  }, [activityInfo]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .patch(`/api/activities/${activityId}`, data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        if (response.status === 204) {
          setIsLoading((prev) => !prev);
          setActivityInfo(data as ActivityModel);

          toast({
            variant: 'default',
            title: 'Success',
            description: 'Activity updated successfully',
            duration: 2000,
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);

        console.log(error);

        toast({
          variant: 'destructive',
          title: 'Activity Update Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          {activityInfo?.title}
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Activity{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex pt-5 pb-3 justify-between">
                <div className="px-2 flex items-center">
                  <Button
                    disabled={!form.formState.isDirty}
                    type="submit"
                    className="w-fit mr-2"
                    size="sm"
                  >
                    {isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}{' '}
                    Update
                  </Button>
                  <Button
                    variant="outline"
                    className="w-fit mr-2"
                    size="sm"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                  <ButtonFormSettings
                    createdByName={activityInfo?.createdByName}
                    modifiedByName={activityInfo?.modifiedByName}
                  />
                </div>
                <ButtonDeleteWithDialog
                  entity="activities"
                  entityId={activityId}
                  hidden={userProfile.id !== activityInfo?.createdById}
                />
              </section>
              <FormSection title="Activity Info">
                <div className="grid grid-cols-3 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Title<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Controller
                    name="activityTypeCode"
                    control={form.control}
                    render={({ field }) => (
                      <FormField
                        control={form.control}
                        name="activityTypeCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Activity Type
                              <span className="text-red-600">*</span>
                            </FormLabel>
                            <FormControl>
                              <Select
                                required
                                {...field}
                                onValueChange={(value) => {
                                  field.onChange.call(null, parseInt(value));

                                  form.setValue(
                                    'activityTypeCode',
                                    parseInt(value),
                                  );

                                  setActivityType(value);

                                  form.reset({
                                    title: form.getValues('title'),
                                    activityTypeCode: parseInt(value),
                                    priorityCode:
                                      form.getValues('priorityCode'),
                                  });
                                }}
                                value={field.value?.toString()}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select activity type" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="1000">Note</SelectItem>
                                    <SelectItem value="1001">
                                      Appointment
                                    </SelectItem>
                                    <SelectItem value="1002">Task</SelectItem>
                                    <SelectItem value="1003">
                                      Phone call
                                    </SelectItem>
                                    <SelectItem value="1004">
                                      Home visit
                                    </SelectItem>
                                    <SelectItem value="1005">
                                      Hospital visit
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="priorityCode"
                    control={form.control}
                    render={({ field }) => (
                      <FormField
                        control={form.control}
                        name="priorityCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Priority</FormLabel>
                            <FormControl>
                              <Select
                                {...field}
                                onValueChange={(value) => {
                                  field.onChange.call(null, parseInt(value));

                                  form.setValue(
                                    'priorityCode',
                                    parseInt(value),
                                  );
                                }}
                                value={field.value?.toString()}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select priority" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="1000">Low</SelectItem>
                                    <SelectItem value="1001">Normal</SelectItem>
                                    <SelectItem value="1002">High</SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  />
                </div>
              </FormSection>
              <FormSection
                title="Note Details"
                hidden={activityType !== '1000'}
              >
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Description<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
              <FormSection
                title="Appointment Details"
                hidden={activityType !== '1001'}
              >
                <div className="grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Date</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="location"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Location</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Description<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
              <FormSection
                title="Task Details"
                hidden={activityType !== '1002'}
              >
                <div className="grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Date</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="location"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Location</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Description<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
              <FormSection
                title="Phone Call Details"
                hidden={activityType !== '1003'}
              >
                <div className="grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Date</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="location"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Location</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Description<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
              <FormSection
                title="Home Visit Details"
                hidden={activityType !== '1004'}
              >
                <div className="grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Date</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="location"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Location</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Description<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
              <FormSection
                title="Home Visit Details"
                hidden={activityType !== '1005'}
              >
                <div className="grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Date</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="location"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Location</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Description<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default ActivityEdit;
