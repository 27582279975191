import { Loader2 } from 'lucide-react';

export const Loading = (props) => {
  return (
    <div className="flex flex-col items-center justify-center h-[80vh]">
      <Loader2 className="text-[var(--color-mint)] animate-spin" size="60px" />
      <p className="text-lg pt-5">Loading...</p>
    </div>
  );
};
