import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import React, { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface ForgotPasswordFormProps {}

const FormSchema = z
  .object({
    email: z.string(),
    confirmedEmail: z.string(),
  })
  .superRefine((val, ctx) => {
    if (val.confirmedEmail !== val.email) {
      ctx.addIssue({
        code: 'custom',
        message: 'Email addresses do not match',
        path: ['confirmedEmail'],
      });
    }
  });

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = () => {
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      confirmedEmail: '',
    },
  });

  useEffect(() => {}, []);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .post('/api/users/forgot-password', data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        setIsLoading((prev) => !prev);

        if (response.data.statusCode === 200) {
          toast({
            variant: 'default',
            title: 'Password reset submitted',
            description: 'Please check your email for further instructions',
            duration: 2000,
          });
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        toast({
          variant: 'destructive',
          title: 'Password Reset Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <Card className="max-w-[400px]">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Forgot Password</CardTitle>
        <CardDescription>
          Please confirm your email address where password reset request should
          be sent
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmedEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Email</FormLabel>
                  <FormControl>
                    <Input type="email" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit">
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{' '}
              Submit
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
