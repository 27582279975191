import { LoginForm } from '@/components/forms';
import React from 'react';

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  return (
    <div className="flex items-center justify-center h-[88%] py-10 px-5">
      <LoginForm />
    </div>
  );
};

export default Login;
