import { AutoCompleteAddress } from '@/components/ui/autocomplete-address';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface MeetingMinutesCreateProps {}

const FormSchema = z.object({
  title: z.string().max(500),
  date: z.string(),
  location: z.string().optional(),
  attendees: z.string(),
  notableAbscences: z.string().optional(),
  agenda: z.string(),
  oldBusiness: z.string().optional(),
  newBusiness: z.string().optional(),
});

const MeetingMinutesCreate: React.FC<MeetingMinutesCreateProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: '',
      date: '',
      location: '',
      attendees: '',
      notableAbscences: '',
      agenda: '',
      oldBusiness: '',
      newBusiness: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .post('/api/meeting-minutes', data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        if (response.data.statusCode === 201) {
          setIsLoading((prev) => !prev);

          toast({
            variant: 'default',
            title: 'Submitted',
            description: 'Meeting minutes submitted successfully',
            duration: 2000,
          });

          navigate(`/deacons/meeting-minutes/${response.data.message}`);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        console.error(error);
        toast({
          variant: 'destructive',
          title: 'Could not submit meeting minutes',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          New Meeting Minutes
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Meeting Minutes{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex ml-2 pt-5 pb-3">
                <Button type="submit" className="w-fit mr-2">
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}{' '}
                  Create
                </Button>
                <Button
                  variant="outline"
                  className="w-fit mr-2"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </section>
              <FormSection title="Meeting Minutes Info">
                <div className="grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Title<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Date<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="location"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Location</FormLabel>
                      <FormControl>
                        <AutoCompleteAddress
                          onValueChange={(params) => {
                            form.setValue('location', params.value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="agenda"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Agenda<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="md:grid grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="attendees"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Attendees<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Textarea required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="notableAbscences"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Notable Abscences</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="oldBusiness"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Old Business</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="newBusiness"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>New Business</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default MeetingMinutesCreate;
