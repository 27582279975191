import React from 'react';

import { ViewCard, ViewCardContent } from '@/components/ui/view-card';

interface ViewCardListProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  title: string;
}

export const ViewCardList: React.FC<ViewCardListProps> = (props) => {
  return (
    <>
      <h2 className="text-3xl font-bold tracking-tight pl-3">{props.title}</h2>
      <div className="w-full py-2 px-3">
        {props.header}
        <ViewCard>
          <ViewCardContent className="h-full">{props.children}</ViewCardContent>
        </ViewCard>
      </div>
    </>
  );
};
