import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import { ButtonDeleteWithDialog } from '@/components/ui/button-delete-with-dialog';
import { ButtonFormSettings } from '@/components/ui/button-form-settings';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface DocumentEditProps {}

const FormSchema = z.object({
  title: z.string(),
  description: z.string(),
  createdByName: z.string().optional(),
  modifiedByName: z.string().optional(),
});

const DocumentEdit: React.FC<DocumentEditProps> = () => {
  const navigate = useNavigate();
  let { documentId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [documentInfo, setdocumentInfo] = useState<DocumentModel>();
  const { userProfile } = React.useContext(UserProfileCtx);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      createdByName: documentInfo?.createdByName,
      modifiedByName: documentInfo?.modifiedByName,
    },
  });

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/documents/${documentId}`)
        .then((response: AxiosResponse<DocumentModel>) => {
          if (response.status === 200) {
            setdocumentInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...documentInfo,
    });
  }, [documentInfo]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    data['documentKey'] = documentInfo?.documentKey;

    await _api
      .patch(`/api/documents/${documentId}`, data)
      .then((response: AxiosResponse<ApiResponse<DocumentModel>>) => {
        setIsLoading((prev) => !prev);
        console.log('response', response);

        if (response.status === 204) {
          // form.reset({}, { keepValues: true });
          setdocumentInfo(data as DocumentModel);

          toast({
            variant: 'default',
            title: 'Updated',
            description: 'Document saved successfully',
            duration: 2000,
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        toast({
          variant: 'destructive',
          title: 'Update Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  const onDownload = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();

    _api
      .post('/api/documents/download/', {
        documentName: documentInfo!.documentKey,
      })
      .then((urlResponse: AxiosResponse<ApiResponse<FileDto>>) => {
        const aElement = document.createElement('a');

        aElement.href = urlResponse.data.message.preSignedUrl;
        aElement.setAttribute('download', urlResponse.data.message.fileName);
        aElement.setAttribute('target', '_blank');
        aElement.click();
        aElement.remove();
      })
      .catch((error: AxiosError) => {
        console.log('downloadError', error);
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          {documentInfo?.title}
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Document{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex justify-between pt-5 pb-3">
                <div className="px-2 flex items-center">
                  <Button
                    disabled={!form.formState.isDirty}
                    type="submit"
                    className="w-fit mr-2"
                    size="sm"
                  >
                    {isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}{' '}
                    Update
                  </Button>
                  <Button
                    variant="outline"
                    className="w-fit mr-2"
                    size="sm"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                  <ButtonFormSettings
                    createdByName={documentInfo?.createdByName}
                    modifiedByName={documentInfo?.modifiedByName}
                  />
                </div>
                <ButtonDeleteWithDialog
                  entity="documents"
                  entityId={documentId}
                  hidden={userProfile.id !== documentInfo?.createdById}
                />
              </section>
              <FormSection title="Document Info">
                <div className="grid grid-cols-1 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Title<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button
                    variant="default"
                    className="w-fit"
                    onClick={onDownload}
                  >
                    <Icons.CloudArrowDown className="text-white w-6 mr-2" />
                    Download
                  </Button>
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Description<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Textarea required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default DocumentEdit;
