import { AutoCompleteAddress } from '@/components/ui/autocomplete-address';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface EventCreateProps {}

const FormSchema = z.object({
  title: z.string().max(500),
  description: z.string().max(2500),
  location: z.string().optional(),
  startDate: z.string(),
  endDate: z.string(),
  eventTypeCode: z.number(),
});

const EventCreate: React.FC<EventCreateProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: '',
      description: '',
      location: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    data.startDate = new Date(data.startDate).toISOString();
    data.endDate = new Date(data.endDate).toISOString();

    await _api
      .post('/api/events', data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        if (response.data.statusCode === 201) {
          setIsLoading((prev) => !prev);

          toast({
            variant: 'default',
            title: 'Submitted',
            description: 'Event created successfully',
            duration: 2000,
          });

          navigate(`/common/events/${response.data.message}`);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);

        console.log(error);

        toast({
          variant: 'destructive',
          title: 'Event create failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          New Event
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Event{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex pl-2 pt-5 pb-3">
                <Button type="submit" className="w-fit mr-2">
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}{' '}
                  Create
                </Button>
                <Button
                  variant="outline"
                  className="w-fit mr-2"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </section>
              <FormSection title="Event Info">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Title<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="location"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel
                          className="inline-flex justify-center items-center"
                          title="Click map to navigate with Google Maps"
                        >
                          Address
                        </FormLabel>
                        <FormControl>
                          <AutoCompleteAddress
                            onValueChange={(params) => {
                              console.log('onvaluechange', params.value);
                              field.value = params.value;
                              form.setValue('location', params.value, {
                                shouldDirty: true,
                              });
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Controller
                    name="eventTypeCode"
                    control={form.control}
                    render={({ field }) => (
                      <FormField
                        control={form.control}
                        name="eventTypeCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Event Type
                              <span className="text-red-600">*</span>
                            </FormLabel>
                            <FormControl>
                              <Select
                                required
                                {...field}
                                onValueChange={(value) => {
                                  field.onChange.call(null, parseInt(value));

                                  form.setValue(
                                    'eventTypeCode',
                                    parseInt(value),
                                  );

                                  form.reset({
                                    title: form.getValues('title'),
                                    description: form.getValues('description'),
                                    location: form.getValues('location'),
                                    eventTypeCode: parseInt(value),
                                    startDate: form.getValues('startDate'),
                                    endDate: form.getValues('endDate'),
                                  });
                                }}
                                value={field.value?.toString()}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Select event type" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="1000">
                                      Church Event
                                    </SelectItem>
                                    <SelectItem value="1001">
                                      Deacon Event
                                    </SelectItem>
                                    <SelectItem value="1002">
                                      Service Event
                                    </SelectItem>
                                    <SelectItem value="1003">
                                      Birthday
                                    </SelectItem>
                                    <SelectItem value="1004">Other</SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="startDate"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Start Date<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input type="datetime-local" required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="endDate"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          End Date<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input type="datetime-local" required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {/* add all day boolean? */}
                </div>
                <div className="grid grid-cols-1">
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Description<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Textarea required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default EventCreate;
