import { ResetPasswordForm } from '@/components/forms';
import React from 'react';

interface ResetPasswordProps {}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  return (
    <div className="flex items-center justify-center h-[88%]">
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
