import { GridData } from '@/components/globals/data';
import { GridMapleTable } from '@/components/ui/grid-maple-table';
import { ViewCardList } from '@/components/ui/view-card-list';
import { useNavigate } from 'react-router-dom';

interface ActivitiesListProps {}

const ActivitiesList: React.FC<ActivitiesListProps> = () => {
  const navigate = useNavigate();

  return (
    <ViewCardList title="Activities">
      <GridMapleTable
        entity="activities"
        gridMapper={GridData.activities.mapper}
        gridColDef={GridData.activities.columns}
        filterSearchColDef={['title', 'description']}
        onAddHandler={() => navigate('/common/activities/new')}
      />
    </ViewCardList>
  );
};

export default ActivitiesList;
