interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="flex h-16 w-full p-5 text-white items-center justify-end bg-zinc-900 text-[16px] md:text-base shadow-md">
      <p>
        <a href="https://cbccumming.org" target="_blank" rel="noreferrer">
          &copy; {new Date().getFullYear()} Concord Baptist Church
        </a>
      </p>
    </div>
  );
};

export default Footer;
