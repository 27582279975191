import { GridData } from '@/components/globals/data';
import { GridMapleTable } from '@/components/ui/grid-maple-table';
import { ViewCardList } from '@/components/ui/view-card-list';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface FeedbackListProps {}

const FeedbackList: React.FC<FeedbackListProps> = () => {
  const navigate = useNavigate();
  return (
    <ViewCardList title="Feedback">
      <GridMapleTable
        entity="feedback"
        gridMapper={GridData.feedback.mapper}
        gridColDef={GridData.feedback.columns}
        filterSearchColDef={['title', 'description']}
        onAddHandler={() => navigate('/admin/feedback/new')}
      />
    </ViewCardList>
  );
};

export default FeedbackList;
