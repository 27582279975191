import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface UserEditProps {}

const FormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phoneNumber: z.string(),
  announcementNotification: z.boolean(),
  announcementResponseNotification: z.boolean(),
  lastLoginDate: z.string(),
});

const UserEdit: React.FC<UserEditProps> = () => {
  const navigate = useNavigate();
  let { userId } = useParams();
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<UserModel>();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const isAdmin = userProfile.roles.indexOf('Admin') !== -1;
  const isUser = userProfile.id === userInfo?.id;
  const shouldDisable = !(isAdmin || !!isUser);

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/users/${userId}`)
        .then((response: AxiosResponse<UserModel>) => {
          if (response.status === 200) {
            setUserInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...userInfo,
      lastLoginDate: moment(userInfo?.lastLoginDate).format('YYYY-MM-DDThh:mm'),
    });
  }, [userInfo]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    data['id'] = userId;

    await _api
      .patch(`/api/users/${userId}`, data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        setIsLoading((prev) => !prev);

        toast({
          variant: 'default',
          title: 'Updated',
          description: 'User updated successfully',
          duration: 2000,
        });

        form.reset({}, { keepValues: true });
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        toast({
          variant: 'destructive',
          title: error.message,
          description: error.message,
          duration: 2000,
        });
      });
  };

  const onRoleUpdate = async () => {
    await _api
      .post(`/api/users/${userId}/roles`, selectedRole)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        toast({
          variant: 'default',
          title: 'Updated',
          description: 'Role created successfully',
          duration: 2000,
        });
      })
      .catch((error: AxiosError<ApiResponse<string>>) => {
        const response = error.response as AxiosResponse<ApiResponse<string>>;

        toast({
          variant: 'destructive',
          title: response.statusText,
          description: response.data.message,
          duration: 2000,
        });
      });
  };

  return (
    <>
      <div className="w-full px-3 sm:px-4">
        <div className="flex flex-col">
          <h2 className="text-3xl font-bold tracking-tight">
            {`${userInfo?.firstName} ${userInfo?.lastName}`}
            <Button
              variant="link"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Icons.ArrowUTurnLeft className="h-6" />
            </Button>
          </h2>
          <p>
            User{' '}
            {Object.keys(form.formState.dirtyFields).length > 0 && (
              <span className="italic text-sm font-normal">
                - Unsaved changes
              </span>
            )}
          </p>
        </div>
        <ViewCard>
          <ViewCardContent className="h-auto">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <section className="flex justify-between px-2 pt-5 pb-3">
                  <div>
                    <Button
                      type="submit"
                      disabled={!form.formState.isDirty}
                      className="w-fit mr-2"
                      size="sm"
                    >
                      {isLoading && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      )}{' '}
                      Update
                    </Button>
                    <Button
                      variant="outline"
                      className="w-fit mr-2"
                      size="sm"
                      onClick={(ev) => {
                        ev.preventDefault();
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  {isAdmin && (
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button
                          className="w-fit bg-[#0dce9c] hover:bg-[#67cfb4] text-black"
                          title="Must be admin to perform this action"
                          size="sm"
                        >
                          Manage Roles
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                          <DialogTitle>Add role</DialogTitle>
                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                          <div className="grid grid-cols-1 items-center">
                            <Select
                              onValueChange={(value) => {
                                setSelectedRole(value);
                              }}
                            >
                              <SelectTrigger className="grid-cols-1">
                                <SelectValue placeholder="Select a role" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value="ChurchMember">
                                    Church Member
                                  </SelectItem>
                                  <SelectItem value="Deacon">Deacon</SelectItem>
                                  <SelectItem value="Admin">Admin</SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                        <DialogFooter>
                          <DialogClose asChild>
                            <Button
                              type="button"
                              onClick={onRoleUpdate}
                              size="sm"
                            >
                              Save changes
                            </Button>
                          </DialogClose>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  )}
                </section>
                <FormSection title="User Information">
                  <div className="grid grid-cols-2 gap-4 pt-2">
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input disabled={shouldDisable} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="lastName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input {...field} disabled={shouldDisable} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input {...field} disabled />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="phoneNumber"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Phone Number</FormLabel>
                          <FormControl>
                            <Input {...field} disabled={shouldDisable} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="lastLoginDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last Login Date</FormLabel>
                          <FormControl>
                            <Input type="datetime-local" disabled {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </FormSection>
                <FormSection title="Notification Preferences">
                  <div className="grid md:grid-cols-3 gap-4 pt-2">
                    <div className="grid-cols-1">
                      <FormField
                        control={form.control}
                        name="announcementNotification"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mb-4">
                            <div className="space-y-0.5">
                              <FormLabel className="text-base">
                                Announcement board notifications
                              </FormLabel>
                              <FormDescription>
                                Receive email notifications when announcements
                                are created
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                                disabled={shouldDisable}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="announcementResponseNotification"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 mb-4">
                            <div className="space-y-0.5">
                              <FormLabel className="text-base">
                                Announcement response notifications
                              </FormLabel>
                              <FormDescription>
                                Receive email notifications when someone
                                responds to announcements you have created
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                                disabled={shouldDisable}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </FormSection>
              </form>
            </Form>
          </ViewCardContent>
        </ViewCard>
      </div>
    </>
  );
};

export default UserEdit;
