import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { createTheme, ThemeProvider } from '@mui/material';
import {
  GridToolbarContainer,
  // GridToolbarExport,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import React, { useContext } from 'react';
import { UserProfileCtx } from '../globals/context';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
  },
});

export const GridToolbar: React.FC<GridToolbarProps> = (props) => {
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);

  return (
    <ThemeProvider theme={theme}>
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <div className={`flex py-3 ${props.hideMenuButtons && 'hidden'}`}>
          <Button
            variant="outline"
            className="mr-4"
            onClick={props.onAddHandler}
          >
            <Icons.Add className="w-4" /> New
          </Button>
          {props.selectedRows!.length > 0 && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="destructive" className="mr-4">
                  <Icons.DeleteSolid className="w-4" /> Delete
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    You are about to delete {props.selectedRows!.length} row/s.
                    Are you sure?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    style={{ background: '#ef4444', color: '#FFF' }}
                    onClick={async (params) => {
                      const requests = props.selectedRows!.map((id) => {
                        return _api
                          .delete(`/api/${props.entity}/${id}`)
                          .then((res) => {
                            return res.data;
                          })
                          .catch((error: AxiosError) => {
                            console.error('delete', error);
                          });
                      });

                      await Promise.allSettled(requests).then(
                        async (results) => {
                          const badRequests = results.filter((result) => {
                            if (result.status === 'fulfilled') {
                              const fullfilled =
                                result as PromiseFulfilledResult<
                                  ApiResponse<string>
                                >;
                              return fullfilled.value.isError;
                            }
                          });

                          if (badRequests.length > 0) {
                            toast({
                              variant: 'destructive',
                              title: 'Partial Delete',
                              description:
                                'One or more records could not be deleted as you are not the owner',
                              duration: 2000,
                            });
                          }
                          await props.setData.call();
                        },
                      );
                    }}
                  >
                    <Icons.Delete className="w-4" /> Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
          {/* <GridToolbarExport /> */}
        </div>

        <GridToolbarQuickFilter
          sx={{
            width: '350px',
            paddingY: '10px',
          }}
        />
      </GridToolbarContainer>
    </ThemeProvider>
  );
};
