import { HeaderFooterLayout } from '@/components/globals';
import { UserProfileCtx } from '@/components/globals/context';
import { ProtectedRoute } from '@/components/globals/router/protected-route';
import { Loading } from '@/components/ui/loading';
import {
  ActivityCreate,
  ActivityEdit,
  ActivityList,
  AnnouncementList,
  ContactCreate,
  ContactEdit,
  ContactList,
  Dashboard,
  DocumentEdit,
  DocumentList,
  EventAttendeeCreate,
  EventAttendeeEdit,
  EventCreate,
  EventEdit,
  EventList,
  FeedbackCreate,
  FeedbackEdit,
  FeedbackList,
  ForgotPassword,
  Home,
  Login,
  MeetingMinutesCreate,
  MeetingMinutesEdit,
  MeetingMinutesList,
  NotFound,
  RegistrationCompletion,
  RegistrationEdit,
  RegistrationList,
  RegistrationRequest,
  ResetPassword,
  Unauthorized,
  UserEdit,
  UserList,
} from '@/pages';
import React, { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InteriorPageLayout from './components/globals/layouts/interior-page-layout';

const App: React.FC = () => {
  let cbcSessionStore = sessionStorage.getItem('cbc');
  let cbcStore;

  if (cbcSessionStore !== null) {
    cbcStore = JSON.parse(cbcSessionStore);
  } else {
    cbcStore = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
      isAuthenticated: false,
    } as UserProfile;
  }
  const [userProfile, setUserProfile] = useState<UserProfile>(cbcStore);

  return (
    <UserProfileCtx.Provider
      value={{
        userProfile: userProfile,
        setUserProfile: setUserProfile,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route element={HeaderFooterLayout}>
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registrations" element={<RegistrationRequest />} />
            <Route
              path="/registrations/:registrationId/new"
              element={<RegistrationCompletion />}
            />
            <Route path="/users/forgot-password" element={<ForgotPassword />} />
            <Route path="/users/reset-password" element={<ResetPassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            <Route element={<ProtectedRoute />}>
              <Route element={InteriorPageLayout}>
                <Route
                  path="/common/dashboard"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Dashboard />
                    </Suspense>
                  }
                />

                <Route path="/common/contacts" element={<ContactList />} />
                <Route
                  path="/common/contacts/new"
                  element={<ContactCreate />}
                />
                <Route
                  path="/common/contacts/:contactId"
                  element={<ContactEdit />}
                />

                <Route
                  path="/common/events"
                  element={
                    <Suspense fallback={<Loading />}>
                      <EventList />
                    </Suspense>
                  }
                />
                <Route path="/common/events/new" element={<EventCreate />} />
                <Route path="/common/events/:eventId" element={<EventEdit />} />

                <Route
                  path="/common/event-attendees/new"
                  element={<EventAttendeeCreate />}
                />
                <Route
                  path="/common/event-attendees/:eventAttendeeId"
                  element={<EventAttendeeEdit />}
                />

                <Route
                  path="/common/announcements"
                  element={<AnnouncementList />}
                />

                <Route path="/common/activities" element={<ActivityList />} />
                <Route
                  path="/common/activities/new"
                  element={<ActivityCreate />}
                />
                <Route
                  path="/common/activities/:activityId"
                  element={<ActivityEdit />}
                />

                <Route
                  path="/deacons/meeting-minutes"
                  element={<MeetingMinutesList />}
                />
                <Route
                  path="/deacons/meeting-minutes/new"
                  element={<MeetingMinutesCreate />}
                />
                <Route
                  path="/deacons/meeting-minutes/:meetingMinutesId"
                  element={<MeetingMinutesEdit />}
                />

                <Route path="/deacons/documents" element={<DocumentList />} />
                <Route
                  path="/deacons/documents/:documentId"
                  element={<DocumentEdit />}
                />

                <Route path="/admin/users" element={<UserList />} />
                <Route path="/admin/users/:userId" element={<UserEdit />} />

                <Route
                  path="/admin/registrations"
                  element={<RegistrationList />}
                />
                <Route
                  path="/admin/registrations/:registrationId"
                  element={<RegistrationEdit />}
                />

                <Route path="/admin/feedback" element={<FeedbackList />} />
                <Route
                  path="/admin/feedback/:feedbackId"
                  element={<FeedbackEdit />}
                />
                <Route
                  path="/admin/feedback/new"
                  element={<FeedbackCreate />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProfileCtx.Provider>
  );
};

export default App;
