import React from 'react';

import ForgotPassword from '@/pages/forgot-password';
import Home from '@/pages/home';
import Login from '@/pages/login';
import NotFound from '@/pages/not-found';
import RegistrationCompletion from '@/pages/registration-completion';
import RegistrationRequest from '@/pages/registration-request';
import ResetPassword from '@/pages/reset-password';
import Unauthorized from '@/pages/unauthorized';

import ActivityCreate from '@/pages/activity-create';
import ActivityEdit from '@/pages/activity-edit';
import ActivityList from '@/pages/activity-list';
import AnnouncementList from '@/pages/announcement-list';
import ContactCreate from '@/pages/contact-create';
import ContactEdit from '@/pages/contact-edit';
import ContactList from '@/pages/contact-list';
import DocumentEdit from '@/pages/document-edit';
import DocumentList from '@/pages/document-list';
import EventAttendeeCreate from '@/pages/event-attendee-create';
import EventAttendeeEdit from '@/pages/event-attendee-edit';
import EventCreate from '@/pages/event-create';
import EventEdit from '@/pages/event-edit';
import FeedbackCreate from '@/pages/feedback-create';
import FeedbackEdit from '@/pages/feedback-edit';
import FeedbackList from '@/pages/feedback-list';
import MeetingMinutesCreate from '@/pages/meeting-minutes-create';
import MeetingMinutesEdit from '@/pages/meeting-minutes-edit';
import MeetingMinutesList from '@/pages/meeting-minutes-list';
import RegistrationEdit from '@/pages/registration-edit';
import RegistrationList from '@/pages/registration-list';
import UserEdit from '@/pages/user-edit';
import UserList from '@/pages/user-list';

const Dashboard = React.lazy(() => import('@/pages/dashboard'));
const EventList = React.lazy(() => import('@/pages/event-list'));

export {
  ActivityCreate,
  ActivityEdit,
  ActivityList,
  AnnouncementList,
  ContactCreate,
  ContactEdit,
  ContactList,
  Dashboard,
  DocumentEdit,
  DocumentList,
  EventAttendeeCreate,
  EventAttendeeEdit,
  EventCreate,
  EventEdit,
  EventList,
  FeedbackCreate,
  FeedbackEdit,
  FeedbackList,
  ForgotPassword,
  Home,
  Login,
  MeetingMinutesCreate,
  MeetingMinutesEdit,
  MeetingMinutesList,
  NotFound,
  RegistrationCompletion,
  RegistrationEdit,
  RegistrationList,
  RegistrationRequest,
  ResetPassword,
  Unauthorized,
  UserEdit,
  UserList,
};
