import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/components/ui/menubar';
import { Icons } from '@/components/ui/icons';
import { Link } from 'react-router-dom';
import { UserProfileCtx } from '../globals/context';
import { useContext } from 'react';

export function Menu() {
  const { userProfile } = useContext(UserProfileCtx);

  const isNotDeaconOrAdmin =
    userProfile.roles.filter((role) => ['Admin', 'Deacon'].indexOf(role) !== -1)
      .length === 0;

  return (
    <Menubar className="lg:hidden rounded-none border-b border-none px-2 lg:px-4">
      <div>
        <MenubarMenu>
          <MenubarTrigger>Common</MenubarTrigger>
          <MenubarContent>
            <Link to="/common/dashboard">
              <MenubarItem>
                <Icons.Dashboards className="h-4 pr-2" />
                Dashboard
              </MenubarItem>
            </Link>
            <Link to="/common/contacts">
              <MenubarItem>
                <Icons.Contacts className="h-4 pr-2" />
                Contacts
              </MenubarItem>
            </Link>
            <Link to="/common/announcements">
              <MenubarItem>
                <Icons.Megaphone className="h-4 pr-2" />
                Announcements
              </MenubarItem>
            </Link>
            <Link to="/common/events">
              <MenubarItem>
                <Icons.Calendar className="h-4 pr-2" />
                Events
              </MenubarItem>
            </Link>
            <Link to="/common/activities">
              <MenubarItem>
                <Icons.Clipboard className="h-4 pr-2" />
                Activities
              </MenubarItem>
            </Link>
          </MenubarContent>
        </MenubarMenu>
      </div>

      <div hidden={isNotDeaconOrAdmin}>
        <MenubarMenu>
          <MenubarTrigger>Deacons</MenubarTrigger>
          <MenubarContent>
            <Link to="/deacons/meeting-minutes">
              <MenubarItem>
                <Icons.MeetingMinutes className="h-4 pr-2" />
                Meeting Minutes
              </MenubarItem>
            </Link>
            <Link to="/deacons/documents">
              <MenubarItem>
                <Icons.Documents className="h-4 pr-2" />
                Documents
              </MenubarItem>
            </Link>
          </MenubarContent>
        </MenubarMenu>
      </div>

      <div>
        <MenubarMenu>
          <MenubarTrigger>Admin</MenubarTrigger>
          <MenubarContent>
            <Link to="/admin/users">
              <MenubarItem>
                <Icons.Users className="h-4 pr-2" />
                Users
              </MenubarItem>
            </Link>
            <Link to="/admin/registrations">
              <MenubarItem>
                <Icons.Registrations className="h-4 pr-2" />
                Registrations
              </MenubarItem>
            </Link>
            <Link to="/admin/feedback">
              <MenubarItem>
                <Icons.Pulse className="h-4 pr-2" />
                Feedback
              </MenubarItem>
            </Link>
          </MenubarContent>
        </MenubarMenu>
      </div>
    </Menubar>
  );
}
