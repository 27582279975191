import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import { ButtonDeleteWithDialog } from '@/components/ui/button-delete-with-dialog';
import { CommentFeed } from '@/components/ui/comment-feed';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

interface CardAnnouncementProps {
  id: string;
  title: string;
  body: string;
  createdById: string;
  createdByName: string;
  date: string;
  openEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
  comments: CommentModel[];
  likes: LikeModel[];
  refreshFeed: (showLoading?: boolean, query?: string) => void;
}

const FormSchema = z.object({
  title: z.string(),
  body: z.string(),
});

export const CardAnnouncement: React.FC<CardAnnouncementProps> = (props) => {
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [hideComments, setHideComments] = useState(true);
  const commentRef = useRef<HTMLInputElement>(null);

  const submitComment = () => {
    if (!commentRef.current?.value) {
      return;
    }

    _api
      .post('/api/comments', {
        announcementId: props.id,
        body: commentRef.current?.value,
      })
      .then((res) => {
        commentRef.current!.value = '';

        props.refreshFeed(true, '/api/announcements');
      })
      .catch((err) => {
        console.error(err);
        toast({
          variant: 'destructive',
          title: 'Could not create comment',
          description: 'An error has occured. Try again later',
          duration: 2000,
        });
      });
  };

  const reformattedBody = () => {
    const text = props.body;

    const urlRegex = /(http[s]?:\/\/[^\s]+)/g;
    const linkedText = text.replace(
      urlRegex,
      '<a class="hyperlink" href="$1" target="_blank" rel="no-referrer">$1</a>',
    );

    return linkedText.replace(/\r\n|\n|\r/g, '<br>');
  };

  const submitAndCloseEditDialog = (data: z.infer<typeof FormSchema>) => {
    _api
      .patch(`/api/announcements/${props.id}`, data)
      .then((res: AxiosResponse<ApiResponse<string>>) => {
        if (res.status === 204) {
          toast({
            variant: 'default',
            title: 'Submitted',
            description: 'Announcement updated successfully',
            duration: 2000,
          });

          setOpenEditDialog((prev) => !prev);

          props.refreshFeed();
        }
      })
      .catch((err: AxiosError) => {
        toast({
          variant: 'destructive',
          title: 'Failed',
          description: 'Failed to update post',
          duration: 2000,
        });

        console.error('Error posting announcement', err);
      });
  };

  const usersLike = props.likes.find(
    (like) => like.createdById === userProfile.id,
  );

  const updateLike = () => {
    if (usersLike?.createdById) {
      _api.delete(`/api/likes/${usersLike.id}`).then((res: AxiosResponse) => {
        props.refreshFeed(false);
      });
    } else {
      _api
        .post('/api/likes', { announcementId: props.id, value: 1 })
        .then((res: AxiosResponse) => {
          props.refreshFeed(false);
        });
    }
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: props.title,
      body: props.body,
    },
  });

  return (
    <div className="rounded-lg border-slate-800 shadow-md border-solid m-2 mb-7 py-2 px-5 bg-white">
      <div
        className={`flex flex-end justify-end ${
          props.createdById !== userProfile.id && 'hidden'
        }`}
      >
        <Dialog open={openEditDialog} onOpenChange={setOpenEditDialog}>
          <DialogTrigger asChild>
            <Button variant="ghost" className="px-1">
              <Icons.PencilSolid className="h-5" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Update announcement</DialogTitle>
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(submitAndCloseEditDialog)}>
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="pb-2">
                      <FormLabel>
                        Title<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="body"
                  render={({ field }) => (
                    <FormItem className="pb-2">
                      <FormLabel>
                        Body<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <DialogClose asChild>
                    <Button type="button" variant="outline" className="mb-2">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button type="submit" className="mb-2">
                    Update
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
        <ButtonDeleteWithDialog
          entity="announcements"
          entityId={props.id}
          variant="ghost"
          buttonText=""
          iconStyles="h-5 text-red-600"
          buttonStyles="px-1"
          onDeleteBehavior="none"
          onDeleteCallback={props.refreshFeed}
        />
      </div>
      <div className="block w-full">
        <h3 className="font-semibold">{props.title}</h3>
      </div>

      <div
        className={`my-2 p-0 border-none text-base`}
        dangerouslySetInnerHTML={{
          __html: reformattedBody(),
        }}
      ></div>
      <div className="flex justify-between items-center font-semibold py-2">
        <div className="flex flex-cols items-center">
          <Button variant="ghost" className={`mr-2 p-1`} onClick={updateLike}>
            {usersLike?.createdById ? (
              <Icons.LikeSolid className="h-5 cursor-pointer  p-0 text-[#289d7e]" />
            ) : (
              <Icons.LikeOutline className="h-5 cursor-pointer  p-0 text-[#289d7e]" />
            )}
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span
                    hidden={props.likes.length === 0}
                    className="button-badge"
                  >
                    {props.likes.length}
                  </span>
                </TooltipTrigger>
                <TooltipContent className="items-start">
                  <ul className="text-left">
                    Liked by:
                    {props.likes.map((like) => (
                      <li key={like.id} className="hyperlink">
                        {like.createdByName}
                      </li>
                    ))}
                  </ul>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
          <span
            onClick={() => {
              setHideComments((prev) => !prev);
            }}
            className="hyperlink italic"
          >
            {props.comments.length > 0
              ? `${props.comments.length} comment/s`
              : '0 comments'}
          </span>
        </div>
        <div className="flex flex-col items-end">
          <Link className="hyperlink italic" to={`/admin/users/1`}>
            {props.createdByName}
          </Link>
          <em className="text-sm font-thin">{props.date}</em>
        </div>
      </div>
      <div className="flex py-2 justify-between">
        <Button
          className="bg-[#0dce9c] hover:bg-[#67cfb4] text-black cursor-pointer mr-2"
          onClick={submitComment}
        >
          <Icons.ArrowRight className="h-5" />
        </Button>
        <Input ref={commentRef} placeholder="Add a comment..." />
      </div>
      <section>
        <CommentFeed
          hideComments={hideComments}
          setHideComments={setHideComments}
          comments={props.comments}
          announcementCreatedById={props.createdById}
        />
      </section>
    </div>
  );
};
