import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { toast } from '@/components/ui/use-toast';
import React from 'react';

interface ButtonFormSettingsProps {
  modifiedByName?: string;
  createdByName?: string;
  additionalFields?: React.ReactNode[];
}

export const ButtonFormSettings: React.FC<ButtonFormSettingsProps> = (
  props,
) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" className="p-1" size="sm">
          <Icons.EllipsesCircle className="h-6 text-slate-600 cursor-pointer" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="md:min-w-[400px]">
        <div className="flex items-center justify-around">
          <Label className="w-32">Share Link</Label>
          <Input
            id="share-link"
            className="mx-3 cursor-not-allowed"
            defaultValue={window.location.href}
            disabled
          />
          <Button
            className="px-3 bg-[#0dce9c] hover:bg-[#67cfb4] text-black"
            onClick={async (ev) => {
              const copiedInput = document.getElementById(
                'share-link',
              ) as HTMLInputElement;
              const copiedValue = copiedInput.value;

              navigator.clipboard
                .writeText(copiedValue)
                .then((result) => {
                  toast({
                    variant: 'default',
                    title: 'Success',
                    description: 'Copied link',
                    duration: 2000,
                  });
                })
                .catch((err) => {
                  toast({
                    variant: 'default',
                    title: 'Failed',
                    description: 'Could not copy link',
                    duration: 2000,
                  });

                  console.error(err);
                });
            }}
          >
            <Icons.Copy className="h-5 mx-1" />
          </Button>
        </div>
        <Separator className="my-4" />
        <div className="flex items-center justify-around py-1">
          <Label className="w-32">Modified By</Label>
          <Input
            className="mx-3 cursor-not-allowed"
            defaultValue={props?.modifiedByName}
            readOnly
          />
        </div>
        <div className="flex items-center justify-around py-1">
          <Label className="w-32">Created By</Label>
          <Input
            className="mx-3 cursor-not-allowed"
            defaultValue={props?.createdByName}
            readOnly
          />
        </div>
        {props.additionalFields?.map((field) => {
          return field;
        })}
      </PopoverContent>
    </Popover>
  );
};
