import { GridData } from '@/components/globals/data';
import { GridMapleTable } from '@/components/ui/grid-maple-table';
import { ViewCardList } from '@/components/ui/view-card-list';
import { useNavigate } from 'react-router-dom';

interface MeetingMinutesProps {}

const MeetingMinutesList: React.FC<MeetingMinutesProps> = () => {
  const navigate = useNavigate();

  return (
    <ViewCardList title="Meeting Minutes">
      <GridMapleTable
        entity="meeting-minutes"
        gridMapper={GridData.meetingMinutes.mapper}
        gridColDef={GridData.meetingMinutes.columns}
        filterSearchColDef={['title']}
        onAddHandler={() => navigate('/deacons/meeting-minutes/new')}
      />
    </ViewCardList>
  );
};

export default MeetingMinutesList;
