import { ForgotPasswordForm } from '@/components/forms';
import React from 'react';

interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  return (
    <div className="flex items-center justify-center h-[88%]">
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
