import { UserProfileCtx } from '@/components/globals/context';
import { GridData } from '@/components/globals/data';
import { AutoCompleteAddress } from '@/components/ui/autocomplete-address';
import { Button } from '@/components/ui/button';
import { ButtonDeleteWithDialog } from '@/components/ui/button-delete-with-dialog';
import { ButtonFormSettings } from '@/components/ui/button-form-settings';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { GridMapleTable } from '@/components/ui/grid-maple-table';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface ContactEditProps {}

const FormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  fullName: z.string().optional(),
  birthday: z.string().optional(),
  age: z.number().optional(),
  yearsAtConcord: z.number().optional(),
  email: z.string().optional(),
  phone1: z.string().optional(),
  phone2: z.string().optional(),
  address: z.string().optional(),
  description: z.string().optional(),
  hometown: z.string().optional(),
  interests: z.string().optional(),
  familyHistory: z.string().optional(),
  needs: z.string().optional(),
  areasOfService: z.string().optional(),
  isMember: z.boolean().optional(),
});

const ContactEdit: React.FC<ContactEditProps> = () => {
  const navigate = useNavigate();
  let { contactId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState<ContactModel>();
  const { userProfile } = useContext(UserProfileCtx);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {},
  });

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/contacts/${contactId}`)
        .then((response: AxiosResponse<ContactModel>) => {
          if (response.status === 200) {
            setContactInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...contactInfo,
    });
  }, [contactInfo]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .patch(`/api/contacts/${contactId}`, data)
      .then((response: AxiosResponse<ApiResponse<FeedbackModel>>) => {
        setIsLoading((prev) => !prev);

        if (response.status === 204) {
          // form.reset({}, { keepValues: true });
          setContactInfo(data as ContactModel);

          toast({
            variant: 'default',
            title: 'Success',
            description: 'Contact updated successfully',
            duration: 2000,
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);

        console.error(error);

        toast({
          variant: 'destructive',
          title: 'Update Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          {contactInfo && `${contactInfo?.fullName}`}
          <Button
            variant="link"
            onClick={() => {
              navigate('/common/contacts');
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Contact{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex justify-between pt-5 pb-3">
                <div className="px-2 flex items-center">
                  <Button
                    disabled={!form.formState.isDirty}
                    type="submit"
                    className="w-fit mr-2"
                    size="sm"
                  >
                    {isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}{' '}
                    Update
                  </Button>
                  <Button
                    variant="outline"
                    className="w-fit mr-2"
                    size="sm"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                  <ButtonFormSettings
                    modifiedByName={contactInfo?.modifiedByName}
                    createdByName={contactInfo?.createdByName}
                    additionalFields={[
                      <div className="flex items-center justify-around py-1">
                        <Label className="w-32">Last Activity Date</Label>
                        <Input
                          className="mx-3 cursor-not-allowed"
                          defaultValue={
                            contactInfo?.lastActivityDate
                              ? new Date(
                                  contactInfo?.lastActivityDate,
                                ).toLocaleString()
                              : ''
                          }
                          readOnly
                        />
                      </div>,
                    ]}
                  />
                </div>
                <ButtonDeleteWithDialog
                  entity="contacts"
                  entityId={contactId}
                  hidden={userProfile.id !== contactInfo?.createdById}
                />
              </section>
              <FormSection title="Personal Info">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 py-2">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          First Name<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Last Name<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input type="email" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="birthday"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Birthday</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="age"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Age</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...form.register('age', { valueAsNumber: true })}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="isMember"
                    render={({ field }) => (
                      <FormItem className="flex justify-center align-middle flex-col">
                        <FormLabel>Is Member</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6 py-2">
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
              <FormSection title="Contact Info">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-2">
                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel
                          className="inline-flex justify-center items-center"
                          title="Click map to navigate with Google Maps"
                        >
                          Address
                          <a
                            href={`https://www.google.com/maps/place/${contactInfo?.address}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icons.Map className="pl-1 h-5" />
                          </a>
                        </FormLabel>
                        <FormControl>
                          <AutoCompleteAddress
                            defaultInputValue={contactInfo?.address}
                            onValueChange={(params) => {
                              field.value = params.value;
                              form.setValue('address', params.value, {
                                shouldDirty: true,
                              });
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-2 gap-6 pt-2">
                  <FormField
                    control={form.control}
                    name="phone1"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone 1</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone2"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone 2</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
              <FormSection title="Additional Info">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-2">
                  <FormField
                    control={form.control}
                    name="areasOfService"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Areas of Service</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="needs"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Needs</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="hometown"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Hometown</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="interests"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Interests</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="familyHistory"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Family History</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="yearsAtConcord"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Years at Concord</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            {...form.register('yearsAtConcord', {
                              valueAsNumber: true,
                            })}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
          <FormSection title="Activities">
            <GridMapleTable
              entity="activities"
              gridMapper={GridData.activitiesContactSubgrid.mapper}
              gridColDef={GridData.activitiesContactSubgrid.columns}
              filterSearchColDef={['title', 'description']}
              onAddHandler={() => {
                const regarding = {
                  contactId: contactId,
                };

                navigate('/common/activities/new', { state: regarding });
              }}
              regardingFilter={`$filter=contactId eq ${contactId}`}
            />
          </FormSection>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default ContactEdit;
