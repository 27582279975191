import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { _handlers } from '@/lib/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState({ email: '' });

  return (
    <>
      <div className="absolute z-20 pl-6 p-5 flex text-lg items-center font-medium semi-bold text-white-500 lg:text-white">
        <picture className="mx-2 w-7">
          <source
            media="(min-width: 1025px)"
            srcSet="https://cbc-deacon-portal-assets.s3.us-east-2.amazonaws.com/img/cbc_logo_light.png"
          />
          <source
            media="(max-width: 1024px)"
            srcSet="https://cbc-deacon-portal-assets.s3.us-east-2.amazonaws.com/img/cbc_logo_dark.png"
          />
          <img src="OtherImage.png" alt="IfItDoesntMatchAnyMedia" />
        </picture>
        <p className="pl-2">Concord Deacon Portal</p>
      </div>
      <div className="container relative grid h-screen bg-gradient-to-br from-zinc-950 to-stone-600 shadow-lg flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col p-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 " />
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <a
                href="https://www.google.com/maps/place/Concord+Baptist+Church/@34.3305028,-84.1239771,17z/data=!3m1!4b1!4m6!3m5!1s0x88f587c9c8d2be3f:0x52b1994c91e33369!8m2!3d34.3305028!4d-84.1214022!16s%2Fg%2F1td0bsl9?entry=ttu"
                target="_blank"
                rel="noreferrer"
                className="text-lg"
              >
                6905 Concord Rd. Cumming, GA 30028
              </a>
              <footer className="text-sm">
                <a href="tel:17708879482">(770) 887-9482</a>
              </footer>
            </blockquote>
          </div>
        </div>
        <div className="flex lg:p-8 h-full bg-white w-full">
          <div className="mx-auto px-16 md:px-48 lg:px-0 flex flex-col justify-center space-y-6 w-screen lg:w-[400px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Request an account
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to request an account
              </p>
            </div>
            <div className="grid gap-6">
              <form>
                <div className="grid gap-2">
                  <div className="grid gap-1">
                    <Label className="sr-only" htmlFor="email">
                      Email
                    </Label>
                    <Input
                      id="email"
                      placeholder="name@example.com"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      disabled={isLoading}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        _handlers.updateFormInputState(ev, setFormState);
                      }}
                    />
                  </div>
                  <Button
                    disabled={isLoading}
                    onClick={(ev) => {
                      _handlers.onClickNavigateWithState(
                        formState.email,
                        '/registrations',
                        navigate,
                      );
                    }}
                  >
                    {isLoading && (
                      <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Submit
                  </Button>
                </div>
              </form>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">
                    Existing user
                  </span>
                </div>
              </div>
              <Button
                variant="outline"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  navigate('/login');
                }}
              >
                {isLoading ? (
                  <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  ''
                )}{' '}
                Login
              </Button>
            </div>
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{' '}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
