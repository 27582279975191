import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import React, { useContext, useState } from 'react';

import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface LoginFormProps {}

const FormSchema = z.object({
  email: z.string(),
  password: z.string().min(8, {
    message:
      'Must include upper/lowercase letters, a number, a special character and min-length of 8 characters',
  }),
});

export const LoginForm: React.FC<LoginFormProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState<string | null>();
  const navigate = useNavigate();
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);

  const hasErrorMessage = new URLSearchParams(window.location.search).get(
    'message',
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .post('/api/users/login', data)
      .then(async (response: AxiosResponse<ApiResponse<string>>) => {
        setIsLoading((prev) => !prev);

        if (response.status === 200) {
          const state = JSON.parse(response.data.message);

          sessionStorage.setItem('cbc', response.data.message);

          setUserProfile(state);

          await _api
            .get('/api/users/token')
            .then((response: AxiosResponse<ApiResponse<string>>) => {})
            .catch((error: AxiosError<ApiResponse<string>>) => {
              console.log('error response from acquiring token', error.message);
            });

          const redirect = new URLSearchParams(window.location.search).get(
            'redirect',
          );

          navigate(redirect ? redirect : '/common/dashboard');
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch((error: AxiosError<ApiResponse<string>>) => {
        setIsLoading((prev) => !prev);

        const response = error.response as AxiosResponse<ApiResponse<string>>;
        console.log('response from axios', response);

        toast({
          variant: 'destructive',
          title: 'Login failed',
          description: response.data.error,
          duration: 2000,
        });
      });
  };

  return (
    <Card className="pb-5">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Login</CardTitle>
        <CardDescription>
          Enter your email and password to log into your account:
        </CardDescription>
      </CardHeader>
      <CardContent>
        {hasErrorMessage && (
          <div className="text-red-500 flex justify-center text-sm">
            <i>{new URLSearchParams(window.location.search).get('message')}</i>
          </div>
        )}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end">
              <Link to="/users/forgot-password">Forgot Password</Link>
            </div>
            <Button className="w-full" type="submit">
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{' '}
              Login
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
