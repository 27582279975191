import { _api } from '@/lib/api';
import { type ClassValue, clsx } from 'clsx';
import { NavigateFunction } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const _handlers = {
  updateFormInputState: (
    ev: React.ChangeEvent<HTMLInputElement>,
    setFormState: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    setFormState((prev) => {
      return {
        ...prev,
        [ev.target.id]: ev.target.value,
      };
    });
  },
  updateFormDivState: (
    id: string,
    value: any,
    setFormState: React.Dispatch<React.SetStateAction<any>>,
  ) => {
    setFormState((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  },
  // onSubmit: (event: React.SyntheticEvent) => {},
  // onSubmitWithLoading: (
  //   event: React.SyntheticEvent,
  //   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  // ) => {
  //   event.preventDefault();
  //   setIsLoading(true);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  // },
  onClickNavigateWithState: (
    state: any,
    page: string,
    navigate: NavigateFunction,
  ) => {
    navigate(page, { state: state });
  },
  onClickLogout: async () => {
    await _api
      .get('/api/users/logout')
      .then((response) => {
        sessionStorage.clear();
        window.location.href = '/';
      })
      .catch((error) => {
        console.log('error', error);
      });
  },
};

export { _handlers };
