import { UserProfileCtx } from '@/components/globals/context';
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);

  return userProfile.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to={`/login?redirect=${encodeURIComponent(window.location.pathname)}`}
    />
  );
};
