import { GridDocumentUploader } from '@/components/ui/grid-document-uploader';
import { ViewCardList } from '@/components/ui/view-card-list';

interface DocumentListProps {}

const DocumentList: React.FC<DocumentListProps> = () => {
  return (
    <ViewCardList title="Documents">
      <GridDocumentUploader />
    </ViewCardList>
  );
};

export default DocumentList;
