import {
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Icons } from '@/components/ui/icons';
import { _api } from '@/lib/api';
import { useDebounce } from '@/lib/hooks/use-debounce';
import { cn } from '@/lib/utils';
import { AxiosResponse } from 'axios';
import { Command as CommandPrimitive } from 'cmdk';
import { Check } from 'lucide-react';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type KeyboardEvent,
} from 'react';

export type Option = Record<'value' | 'label', string> & Record<string, string>;

type AutoCompleteAddressProps = {
  emptyMessage?: string;
  value?: Option;
  onValueChange?: (value: Option) => void;
  isLoading?: boolean;
  disabled?: boolean;
  defaultInputValue?: string;
};

export const AutoCompleteAddress = ({
  emptyMessage = 'Type to search...',
  value,
  onValueChange,
  disabled = false,
  isLoading = false,
  defaultInputValue = '',
}: AutoCompleteAddressProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [options, setOptions] = useState<Option[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState<Option>(value as Option);
  const [keyDownEvent, setKeyDownEvent] =
    useState<KeyboardEvent<HTMLDivElement>>();
  const [inputValue, setInputValue] = useState<string>(defaultInputValue);

  const debouncedEvent = useDebounce(keyDownEvent, 500);

  useEffect(() => {
    _handlers.onKeyDown(keyDownEvent as KeyboardEvent<HTMLDivElement>);
  }, [debouncedEvent]);

  const _handlers = {
    onKeyDown: useCallback(
      async (event: KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (!input?.value) {
          return;
        }

        if (!isOpen) {
          setOpen(true);
        }

        const response: AxiosResponse<GooglePlacesResponse> = await _api.get(
          `/api/addresses/${input.value}`,
        );

        if (response.data.results.length === 0) {
          return;
        }

        setOptions(
          response.data.results.map((result) => {
            return {
              key: result.placeId,
              value: result.formattedAddress,
              label:
                result.formattedAddress.indexOf(result.name) !== -1
                  ? result.formattedAddress
                  : `${result.name} (${result.formattedAddress})`,
            };
          }),
        );

        // This is not a default behaviour of the <input /> field
        // if (event.key === 'Enter' && input.value !== '') {
        //   const optionToSelect = options.find(
        //     (option) => option.label === input.value,
        //   );
        //   if (optionToSelect) {
        //     setSelected(optionToSelect);
        //     onValueChange?.(optionToSelect);
        //   }
        // }

        if (event.key === 'Escape') {
          input.blur();
        }
      },
      [isOpen, options, onValueChange],
    ),
    onBlur: useCallback(() => {
      setOpen(false);
      setInputValue(selected?.value);
    }, [selected]),
    onSelect: useCallback(
      (selectedOption: Option) => {
        setInputValue(selectedOption.value);

        setSelected(selectedOption);
        onValueChange?.(selectedOption);

        setTimeout(() => {
          inputRef?.current?.blur();
        }, 0);
      },
      [onValueChange],
    ),
  };

  useEffect(() => {
    if (defaultInputValue) {
      const option: Option = {
        key: '1',
        label: defaultInputValue,
        value: defaultInputValue,
      };

      _handlers.onSelect(option);
    }
  }, [defaultInputValue]);

  return (
    <CommandPrimitive
      onKeyDown={(e) => {
        setKeyDownEvent(e);
      }}
    >
      <div>
        <CommandInput
          ref={inputRef}
          value={inputValue}
          onValueChange={isLoading ? undefined : setInputValue}
          onBlur={_handlers.onBlur}
          onFocus={() => setOpen(true)}
          disabled={disabled}
          className="text-base h-[38px]"
          startAdornment={
            <Icons.MapPin className="mr-2 w-5 shrink-0 opacity-50" />
          }
        />
      </div>
      <div className="relative mt-1">
        <div
          className={`animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none
            ${isOpen ? 'block' : 'hidden'}`}
        >
          <CommandList className="rounded-lg ring-1 ring-slate-200">
            {options.length > 0 && !isLoading ? (
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selected?.value === option.value;
                  return (
                    <CommandItem
                      key={option.key}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      onSelect={() => _handlers.onSelect(option)}
                      className={cn(
                        'flex w-full items-center gap-2',
                        !isSelected ? 'pl-8' : null,
                      )}
                    >
                      {isSelected ? <Check className="w-4" /> : null}
                      {option.label}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            ) : null}
            {!isLoading ? (
              <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                {emptyMessage}
              </CommandPrimitive.Empty>
            ) : null}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  );
};
