import * as React from 'react';

import { cn } from '@/lib/utils';

const ViewCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'rounded-lg border bg-card text-card-foreground shadow-md my-2',
      className,
    )}
    {...props}
  />
));
ViewCard.displayName = 'ViewCard';

const ViewCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col space-y-1.5 p-3', className)}
    {...props}
  />
));
ViewCardHeader.displayName = 'CardHeader';

const ViewCardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'text-2xl font-semibold leading-none tracking-tight',
      className,
    )}
    {...props}
  />
));
ViewCardTitle.displayName = 'CardTitle';

const ViewCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
ViewCardDescription.displayName = 'CardDescription';

const ViewCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('px-3 pt-0 pb-2', className)} {...props} />
));
ViewCardContent.displayName = 'CardContent';

const ViewCardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center p-3 pt-0', className)}
    {...props}
  />
));
ViewCardFooter.displayName = 'CardFooter';

export {
  ViewCard,
  ViewCardContent,
  ViewCardDescription,
  ViewCardFooter,
  ViewCardHeader,
  ViewCardTitle,
};
