import { UserProfileCtx } from '@/components/globals/context';
import React, { useContext } from 'react';
import { Icons } from './icons';

interface CommentFeedProps {
  hideComments: boolean;
  setHideComments: React.Dispatch<React.SetStateAction<boolean>>;
  comments?: CommentModel[];
  announcementCreatedById: string;
}

export const CommentFeed: React.FC<CommentFeedProps> = ({
  hideComments = true,
  setHideComments,
  comments = [],
  announcementCreatedById,
}) => {
  const { userProfile } = useContext(UserProfileCtx);

  return (
    <div hidden={hideComments} className="comment-feed">
      <ol className="comment-feed-list">
        {comments.map((item) => (
          <li
            key={item.id}
            className={`my-2 shadow-md comment-feed-shared ${
              announcementCreatedById === item.createdById ? 'received' : 'sent'
            }`}
          >
            {item.body}
            <p className="italic text-sm">
              Sent by: <span className="underline">{item.createdByName}</span>
            </p>
          </li>
        ))}
      </ol>
      <div className="flex py-2 justify-center">
        <Icons.ChevronDoubleUp
          className="w-7 text-emerald-600 cursor-pointer hover:bg-slate-100 rounded-md"
          onClick={() => {
            setHideComments((prev) => !prev);
          }}
        />
      </div>
    </div>
  );
};
