import { UserProfileCtx } from '@/components/globals/context';
import { GridData } from '@/components/globals/data';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Icons } from '@/components/ui/icons';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { createTheme, ThemeProvider } from '@mui/material';
import {
  DataGrid,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarProps,
} from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#059669',
    },
  },
});

type GridToolbarAttendeeProps = GridToolbarProps & {
  selectedRows?: GridRowSelectionModel[];
  entity?: string;
  setData?: () => void;
  regardingEventId?: string;
};

export const GridToolbarAttendee: React.FC<GridToolbarAttendeeProps> = (
  props,
) => {
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [userRows, setUserRows] = useState<UserModel[]>([]);
  const [selectedRowModel, setSelectedRowModel] =
    useState<GridRowSelectionModel>([]);

  useEffect(() => {
    (async () => {
      await _api
        .get('/api/users')
        .then(async (response: AxiosResponse<UserModel[]>) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }

          const data = response.data.map(GridData.user.mapper);

          setUserRows((prev) => data);
        })
        .catch((error: AxiosError<ApiResponse<string>>) => {
          const response = error.response as AxiosResponse<ApiResponse<string>>;

          console.error('response from axios', response);
        });
    })();
  }, []);

  const onSubmit = async () => {
    const eventAttendeeRequests = selectedRowModel.map((id) => {
      return _api.post('/api/event-attendees', {
        eventId: props.regardingEventId,
        userId: id,
      });
    });

    await Promise.all(eventAttendeeRequests)
      .then(async (result) => {
        if (props.setData) {
          await props.setData();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <div className={`flex py-3`}>
          <Dialog open={openAddDialog} onOpenChange={setOpenAddDialog}>
            <DialogTrigger asChild>
              <Button variant="outline" className="mr-4">
                <Icons.Add className="w-4" /> New
              </Button>
            </DialogTrigger>
            <DialogContent
              className="sm:max-w-[600px] max-h-[450px] block"
              aria-describedby="Add attendees dialog"
            >
              <DialogHeader>
                <DialogTitle>Add attendees</DialogTitle>
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={userRows}
                    columns={GridData.user.columns}
                    density="compact"
                    onRowSelectionModelChange={(e) => {
                      setSelectedRowModel((prev) => e);
                    }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                    }}
                    disableColumnFilter
                    paginationMode="client"
                    pageSizeOptions={[]}
                    checkboxSelection
                    sx={{
                      maxHeight: '450px',
                      border: 'none',
                      width: '100%',
                      marginTop: '5px',
                      '&.Mui-checked': {
                        color: '#059669',
                      },
                      '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: '#defdf5',
                        '&:hover': {
                          backgroundColor: '#c6fbed',
                        },
                      },
                      '& .MuiDataGrid-toolbarQuickFilter': {
                        width: '100%',
                        maxWidth: '400px',
                      },
                    }}
                  />
                </ThemeProvider>
              </DialogHeader>
              <DialogFooter>
                <DialogClose asChild>
                  <Button className="mb-2" onClick={onSubmit}>
                    Submit
                  </Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button type="button" variant="outline" className="mb-2">
                    Cancel
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          {props.selectedRows!.length > 0 && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="destructive" className="mr-4">
                  <Icons.DeleteSolid className="w-4" /> Delete
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    You are about to delete {props.selectedRows!.length} row/s.
                    Are you sure?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    style={{ background: '#ef4444', color: '#FFF' }}
                    onClick={async (params) => {
                      const requests = props.selectedRows!.map((id) => {
                        return _api
                          .delete(`/api/${props.entity}/${id}`)
                          .then((res) => {
                            return res.data;
                          })
                          .catch((error: AxiosError) => {
                            console.error('delete', error);
                          });
                      });

                      await Promise.allSettled(requests).then(
                        async (results) => {
                          const badRequests = results.filter((result) => {
                            if (result.status === 'fulfilled') {
                              const fullfilled =
                                result as PromiseFulfilledResult<
                                  ApiResponse<string>
                                >;
                              return fullfilled.value.isError;
                            }
                          });

                          if (badRequests.length > 0) {
                            toast({
                              variant: 'destructive',
                              title: 'Partial Delete',
                              description:
                                'One or more records could not be deleted as you are not the owner',
                              duration: 2000,
                            });
                          }
                          if (props.setData) {
                            await props.setData.call(null);
                          }
                        },
                      );
                    }}
                  >
                    <Icons.Delete className="w-4" /> Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </GridToolbarContainer>
    </ThemeProvider>
  );
};
