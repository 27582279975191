import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import { _api } from '@/lib/api';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ButtonDeleteWithDialogProps {
  entity: string;
  entityId: string | undefined;
  deleteCount?: number;
  hidden?: boolean;
  buttonText?: string;
  buttonStyles?: string;
  iconStyles?: string;
  onDeleteBehavior?: 'none' | 'back';
  onDeleteCallback?: () => void;
  variant?:
    | 'destructive'
    | 'link'
    | 'default'
    | 'outline'
    | 'secondary'
    | 'ghost'
    | null
    | undefined;
}

export const ButtonDeleteWithDialog: React.FC<ButtonDeleteWithDialogProps> = ({
  entity,
  entityId,
  deleteCount = 1,
  hidden = false,
  variant = 'destructive',
  buttonText = 'Delete',
  buttonStyles = 'mr-2',
  iconStyles = 'h-5 mr-1',
  onDeleteBehavior = 'back',
  onDeleteCallback = () => {},
}) => {
  const navigate = useNavigate();
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        {!hidden && (
          <Button variant={variant} className={buttonStyles} size="sm">
            <Icons.DeleteSolid className={iconStyles} /> {buttonText}
          </Button>
        )}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to permanently delete {deleteCount} record/s and all
            related records. Are you sure you want to continue?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            style={{ background: '#ef4444', color: '#FFF' }}
            onClick={async (params) => {
              await _api
                .delete(`/api/${entity}/${entityId}`)
                .then((response: AxiosResponse<ApiResponse<string>>) => {
                  onDeleteCallback();

                  if (onDeleteBehavior === 'back') {
                    navigate(-1);
                  }
                })
                .catch((error: AxiosError) => {});
            }}
          >
            <Icons.Delete className="w-4" /> Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
