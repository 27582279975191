import { GridData } from '@/components/globals/data';
import { GridMapleTable } from '@/components/ui/grid-maple-table';
import { ViewCardList } from '@/components/ui/view-card-list';
import { createTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ContactListProps {}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0dce9c',
    },
  },
});

export const ContactList: React.FC<ContactListProps> = () => {
  const navigate = useNavigate();
  return (
    <ViewCardList title="Contacts">
      <GridMapleTable
        entity="contacts"
        gridMapper={GridData.contact.mapper}
        gridColDef={GridData.contact.columns}
        filterSearchColDef={['fullName', 'email']}
        onAddHandler={() => navigate('/common/contacts/new')}
      />
    </ViewCardList>
  );
};

export default ContactList;
