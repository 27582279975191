import { Footer, Header } from '@/components/globals';
import { Toaster } from '@/components/ui/toaster';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

const HeaderFooterLayout = (
  <div className="flex flex-col h-screen">
    <Header />
    <Outlet />
    <Toaster />
    <Footer />
  </div>
) as ReactNode;

export default HeaderFooterLayout;
