import { Button } from '@/components/ui/button-ny';
import { Icons } from '@/components/ui/icons';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserProfileCtx } from '@/components/globals/context';

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export function Sidebar(props) {
  const { userProfile } = useContext(UserProfileCtx);

  const isNotDeaconOrAdmin =
    userProfile.roles.filter((role) => ['Admin', 'Deacon'].indexOf(role) !== -1)
      .length === 0;

  return (
    <div
      key="cbc-sidebar"
      className={`hidden lg:grid bg-white shadow-md ${props.className}`}
    >
      <div className="py-2 pl-3 h-screen">
        <div className="py-2">
          <h2 className="mb-2 text-lg font-semibold tracking-tight">Common</h2>
          <div className="space-y-1 pl-1">
            <Link to="/common/dashboard">
              <Button
                id="sidenav-btn-dashboard"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Dashboards className="h-6 pr-2" />
                Dashboard
              </Button>
            </Link>
            <Link to="/common/contacts">
              <Button
                id="sidenav-btn-contacts"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Contacts className="h-6 pr-2" />
                Contacts
              </Button>
            </Link>
            <Link to="/common/announcements">
              <Button
                id="sidenav-btn-events"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Megaphone className="h-6 pr-2" />
                Announcements
              </Button>
            </Link>
            <Link to="/common/events">
              <Button
                id="sidenav-btn-events"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Calendar className="h-6 pr-2" />
                Events
              </Button>
            </Link>
            <Link to="/common/activities">
              <Button
                id="sidenav-btn-events"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Clipboard className="h-6 pr-2" />
                Activities
              </Button>
            </Link>
          </div>
        </div>

        <div className="py-2" hidden={isNotDeaconOrAdmin}>
          <h2 className="mb-2 text-lg font-semibold tracking-tight">Deacons</h2>
          <div className="space-y-1 pl-1">
            <Link to="/deacons/meeting-minutes">
              <Button
                id="sidenav-btn-meeting-minutes"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.MeetingMinutes className="h-6 pr-2" />
                Meeting Minutes
              </Button>
            </Link>
            <Link to="/deacons/documents">
              <Button
                id="sidenav-btn-documents"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Documents className="h-6 pr-2" />
                Documents
              </Button>
            </Link>
          </div>
        </div>

        <div className="py-2">
          <h2 className="mb-2 text-lg font-semibold tracking-tight">Admin</h2>
          <div className="space-y-1 md:pl-1">
            <Link to="/admin/users">
              <Button
                id="sidenav-btn-users"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Users className="h-6 pr-2" />
                Users
              </Button>
            </Link>
            <Link to="/admin/registrations">
              <Button
                id="sidenav-btn-registrations"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Registrations className="h-6 pr-2" />
                Registrations
              </Button>
            </Link>
            <Link to="/admin/feedback">
              <Button
                id="sidenav-btn-feedback"
                variant="ghost"
                className="w-full justify-start text-wrap text-sm xl:text-base pl-2"
              >
                <Icons.Pulse className="h-6 pr-2" />
                Feedback
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
