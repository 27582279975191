import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import React, { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface ResetPasswordFormProps {}

const FormSchema = z
  .object({
    password: z.string().min(8, {
      message:
        'Must include upper/lowercase letters, a number, a special character and min-length of 8 characters',
    }),
    confirmedPassword: z.string().min(8, {
      message:
        'Must include upper/lowercase letters, a number, a special character and min-length of 8 characters',
    }),
  })
  .superRefine((val, ctx) => {
    if (val.confirmedPassword !== val.password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmedPassword'],
      });
    }
  });

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: '',
      confirmedPassword: '',
    },
  });

  useEffect(() => {}, []);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    const urlParams = new URLSearchParams(window.location.search);
    data['email'] = urlParams.get('email');
    data['token'] = urlParams.get('token');

    await _api
      .post('/api/users/reset-password', data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        setIsLoading((prev) => !prev);

        if (response.data.statusCode === 200) {
          navigate('/login');
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        toast({
          variant: 'destructive',
          title: 'Reset Password Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <Card className="max-w-[400px]">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">
          Password Reset Request
        </CardTitle>
        <CardDescription>Please provide updated password:</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmedPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input type="password" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit">
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{' '}
              Submit
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
