import { RegistrationRequestForm } from '@/components/forms';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface RegistrationRequestProps {}

const RegistrationRequest: React.FC<RegistrationRequestProps> = () => {
  const { state } = useLocation(); // from homepage request-account-form

  return (
    <div className="flex items-center justify-center md:h-[88%] h-full py-10 px-5">
      <RegistrationRequestForm passThroughFormState={state} />
    </div>
  );
};

export default RegistrationRequest;
