import { GridToolbar } from '@/components/ui/grid-toolbar';
import { _api } from '@/lib/api';
import { useDebounce } from '@/lib/hooks/use-debounce';
import { createTheme, ThemeProvider } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

interface GridMapleTableProps<T> {
  entity: string;
  gridMapper: (item: T) => T;
  gridColDef: GridColDef[];
  filterSearchColDef: string[];
  hideMenuButtons?: boolean;
  onAddHandler?: () => void;
  regardingFilter?: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#059669',
    },
  },
});

export const GridMapleTable = <T,>({
  entity,
  gridMapper,
  gridColDef,
  filterSearchColDef,
  hideMenuButtons = false,
  onAddHandler,
  regardingFilter = '',
}: GridMapleTableProps<T>) => {
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectRowModel, setSelectRowModel] = useState<GridRowSelectionModel>(
    [],
  );
  // const [rowTotal, setRowTotal] = useState(0);
  // const [searchTerm, setSearchTerm] = useState('');

  const [queryFilters, setQueryFilters] = useState({
    // top: '$top=100',
    // skip: '$skip=0',
    orderBy: '$orderBy=id desc',
    regardingFilter: regardingFilter,
  });
  // const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const setData = async (searchTerm?: string) => {
    setIsLoading((prev) => !prev);

    // let query = '';
    // if (searchTerm) {
    //   const filterSearchCol = filterSearchColDef
    //     .map((field) => {
    //       return `contains(toLower(${field}), toLower('${searchTerm}'))`;
    //     })
    //     .join(' or ');

    //   const filter = `$filter=${filterSearchCol}`;

    //   query = `/api/${entity}?${filter}`;
    // } else {
    //   const params = [
    //     queryFilters.top,
    //     queryFilters.skip,
    //     queryFilters.orderBy,
    //     regardingFilter,
    //   ].join('&');

    //   query = `/api/${entity}?${params}`;
    // }

    const params = [queryFilters.orderBy, regardingFilter].join('&');

    await _api
      .get(`/api/${entity}?${params}`)
      .then(async (response: AxiosResponse<T[]>) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        const data = response.data.map(gridMapper);

        setRows((prev) => data);

        setIsLoading((prev) => !prev);

        // if (data.length % 100 > 0) {
        //   apiRef.current.setRowCount(rowTotal);
        // } else {
        //   apiRef.current.setRowCount(-1);
        // }
      })
      .catch((error: AxiosError<ApiResponse<string>>) => {
        const response = error.response as AxiosResponse<ApiResponse<string>>;

        console.error('response from axios', response);
      });
  };

  useEffect(() => {
    (async () => {
      await setData();
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await setData(debouncedSearchTerm);
  //   })();
  // }, [queryFilters, debouncedSearchTerm]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          autoHeight={false}
          columns={gridColDef}
          loading={isLoading}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100 },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              selectedRows: selectRowModel,
              entity: entity,
              hideMenuButtons: hideMenuButtons,
              onAddHandler: onAddHandler,
              setData: setData,
            },
            baseCheckbox: {
              color: 'primary',
            },
          }}
          disableAutosize
          onRowSelectionModelChange={(e) => {
            setSelectRowModel(e);
          }}
          paginationMode="client"
          // onPaginationModelChange={(e: GridPaginationModel) => {
          //   setRowTotal((e.page + 1) * 100);

          //   setQueryFilters((prev) => {
          //     return {
          //       ...prev,
          //       skip: `$skip=${e.page * 100}`,
          //     };
          //   });
          // }}
          filterMode="client"
          // filterDebounceMs={1000}
          // onFilterModelChange={(e: GridFilterModel) => {
          //   console.log(e);
          // }}
          // onSortModelChange={(e: GridSortModel) => {
          //   let query = '';

          //   if (e.length === 0) {
          //     query = '$orderBy=createdOn desc';
          //   } else {
          //     const param = e[0];
          //     const { field, sort } = param;

          //     query = `$orderBy=${field} ${sort}`;
          //   }

          //   setQueryFilters((prev) => {
          //     return {
          //       ...prev,
          //       orderBy: query,
          //     };
          //   });
          // }}
          // onFilterModelChange={(ev) => {
          //   if (ev.quickFilterValues?.length !== 0) {
          //     const value = ev.quickFilterValues as string[];

          //     setSearchTerm((prev) => value[0]);
          //   } else {
          //     setSearchTerm((prev) => '');
          //   }
          // }}
          // loading={isLoading} // remove until odata fix is in
          pageSizeOptions={[100]}
          checkboxSelection
          sx={{
            minHeight: '450px',
            border: 'none',
            width: '100%',
            marginTop: '5px',
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#defdf5',
              '&:hover': {
                backgroundColor: '#c6fbed',
              },
            },
            '& .MuiDataGrid-toolbarQuickFilter': {
              width: '100%',
              maxWidth: '400px',
            },
          }}
        />
      </ThemeProvider>
    </>
  );
};
