import { createContext } from 'react';

type UserProfileCtx = {
  userProfile: UserProfile;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile>>;
};

export const UserProfileCtx = createContext<UserProfileCtx>({
  userProfile: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    isAuthenticated: false,
  },
  setUserProfile: () => {},
});
