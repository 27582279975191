import { RegistrationCompletionForm } from '@/components/forms';
import React from 'react';

interface RegistrationCompletionProps {}

const RegistrationCompletion: React.FC<RegistrationCompletionProps> = () => {
  return (
    <div className="flex items-center justify-center h-[88%]">
      <RegistrationCompletionForm />
    </div>
  );
};

export default RegistrationCompletion;
