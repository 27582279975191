import { GridData } from '@/components/globals/data';
import { GridToolbar } from '@/components/ui/grid-toolbar';
import { Icons } from '@/components/ui/icons';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { useDebounce } from '@/lib/hooks/use-debounce';
import { createTheme, ThemeProvider } from '@mui/material';
import {
  DataGrid,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface GridDocumentUploaderProps {
  refreshGrid?: Dispatch<SetStateAction<number>>; // delete
  regardingObject?: RegardingObject;
  regardingFilter?: string;
  className?: string;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#059669',
    },
  },
});

export const GridDocumentUploader: React.FC<GridDocumentUploaderProps> = (
  props,
) => {
  const [dragActive, setDragActive] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<DocumentModel[]>([]);
  // const [rowTotal, setRowTotal] = useState(0);
  // const [searchTerm, setSearchTerm] = useState('');
  const [selectRowModel, setSelectRowModel] = useState<GridRowSelectionModel>(
    [],
  );

  const [queryFilters, setQueryFilters] = useState({
    // top: '$top=100',
    // skip: '$skip=0',
    orderBy: '$orderBy=id desc',
    regardingFilter: null,
  });
  // const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    setData();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await setData(debouncedSearchTerm);
  //   })();
  // }, [queryFilters, debouncedSearchTerm]);

  const upload = async (file: File) => {
    let formData = new FormData();
    formData.append('formFile', file);

    return _api
      .post(`/api/documents/upload`, formData, {
        headers: {
          'Content-Type': '',
        },
      })
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        if (response.status === 201) {
          return response.data.message;
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch((error: AxiosError<ApiResponse<string>>) => {
        console.error('upload', error);
      });
  };

  const setData = async (searchTerm?: string) => {
    // let query = '';
    // if (searchTerm) {
    //   const filterSearchCol = ['title', 'description']
    //     .map((field) => {
    //       return `contains(toLower(${field}), toLower('${searchTerm}'))`;
    //     })
    //     .join(' or ');

    //   const filter = `$filter=${filterSearchCol}`;

    //   query = `/api/documents?${filter}`;
    // } else {
    //   const params = [
    //     queryFilters.top,
    //     queryFilters.skip,
    //     queryFilters.orderBy,
    //     props.regardingFilter && props.regardingFilter,
    //   ].join('&');

    //   query = `/api/documents?${params}`;
    // }

    const params = [queryFilters.orderBy, queryFilters.regardingFilter].join(
      '&',
    );

    await _api
      .get(`/api/documents?${params}`)
      .then(async (response: AxiosResponse<DocumentModel[]>) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        const data = response.data.map(GridData.document.mapper);

        setRows((prev) => data);

        // if (data.length % 100 > 0) {
        //   apiRef.current.setRowCount(rowTotal);
        // } else {
        //   apiRef.current.setRowCount(-1);
        // }
      })
      .catch((error: AxiosError<ApiResponse<string>>) => {
        const response = error.response as AxiosResponse<ApiResponse<string>>;

        console.error('response from axios', response);
      });
  };

  const _handlers = {
    onDrag: (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    },
    onDrop: async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setDragActive(false);
      setIsLoadingUpload((prev) => !prev);

      const fileUploadRequests = Object.entries(e.dataTransfer.files).map(
        async ([key, file]) => {
          const response = await upload(file);

          return response;
        },
      );

      const s3Result = (await Promise.all(fileUploadRequests)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error(error);
        })) as string[];

      const dbCreateRequests = s3Result.map(async (result) => {
        const regex = /[\w]\/(.*?)_/;
        const match = result.match(regex);
        let selectedText = result;

        if (match) {
          selectedText = match[1];
        }

        let document = {
          title: selectedText,
          description: 'PLACEHOLDER: Manually uploaded',
          documentKey: result,
        } as DocumentModel;

        document = {
          ...document,
          ...(props.regardingObject ? { ...props.regardingObject } : {}),
        };

        const response = await _api.post(`/api/documents`, document);

        return response.data;
      });

      Promise.all(dbCreateRequests)
        .then(() => {
          setIsLoadingUpload((prev) => !prev);

          toast({
            variant: 'default',
            title: 'Success',
            description: 'Created document/s',
            duration: 2000,
          });

          setData();
        })
        .catch((error) => {
          setIsLoadingUpload((prev) => !prev);

          toast({
            variant: 'destructive',
            title: 'Failed',
            description: error.message,
            duration: 2000,
          });
        });
    },
    onChange: async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsLoadingUpload((prev) => !prev);

      const fileUploadRequests = Object.entries(e.target.files as FileList).map(
        async ([key, file]) => {
          const response = await upload(file);

          return response;
        },
      );

      const s3Result = (await Promise.all(fileUploadRequests)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error(error);
        })) as string[];

      const dbCreateRequests = s3Result.map(async (result) => {
        const regex = /[\w]\/(.*?)_/;
        const match = result.match(regex);
        let selectedText = result;

        if (match) {
          selectedText = match[1];
        }

        let document = {
          title: selectedText,
          description: 'PLACEHOLDER: Manually uploaded',
          documentKey: result,
        } as DocumentModel;

        document = {
          ...document,
          ...(props.regardingObject ? { ...props.regardingObject } : {}),
        };

        const response = await _api.post(`/api/documents`, document);

        return response.data;
      });

      Promise.all(dbCreateRequests)
        .then(() => {
          setIsLoadingUpload((prev) => !prev);

          toast({
            variant: 'default',
            title: 'Success',
            description: 'Created document/s',
            duration: 2000,
          });

          setData();
        })
        .catch((error) => {
          setIsLoadingUpload((prev) => !prev);

          toast({
            variant: 'destructive',
            title: 'Failed',
            description: error.message,
            duration: 2000,
          });
        });
    },
  };

  return (
    <div>
      <form id="file-uploader" className="py-3" onDragEnter={_handlers.onDrag}>
        <input
          id="input-file-uploader"
          name="formFile"
          type="file"
          multiple={true}
          onChange={_handlers.onChange}
          className="z-10"
        />
        <label
          id="label-file-uploader"
          htmlFor="input-file-uploader"
          className={`flex flex-col cursor-pointer ${
            dragActive ? 'drag-active' : ''
          }`}
        >
          <p>Click here to upload or drag/drop files</p>
          {isLoadingUpload ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <Icons.CloudArrowUp className="w-8" />
          )}
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDrag={_handlers.onDrag}
            onDragExit={_handlers.onDrag}
            onDragOver={_handlers.onDrag}
            onDrop={_handlers.onDrop}
          ></div>
        )}
      </form>
      <ThemeProvider theme={theme}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          autoHeight={false}
          columns={GridData.document.columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100 },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              selectedRows: selectRowModel,
              entity: 'documents',
              hideMenuButtons: true,
              setData: setData,
            },
            baseCheckbox: {
              color: 'primary',
            },
          }}
          disableAutosize
          onRowSelectionModelChange={(e) => {
            setSelectRowModel(e);
          }}
          paginationMode="client"
          // onPaginationModelChange={(e: GridPaginationModel) => {
          //   setRowTotal((e.page + 1) * 100);

          //   setQueryFilters((prev) => {
          //     return {
          //       ...prev,
          //       skip: `$skip=${e.page * 100}`,
          //     };
          //   });
          // }}
          filterMode="client"
          // onSortModelChange={(e: GridSortModel) => {
          //   let query = '';

          //   if (e.length === 0) {
          //     query = '$orderBy=createdOn desc';
          //   } else {
          //     const param = e[0];
          //     const { field, sort } = param;

          //     query = `$orderBy=${field} ${sort}`;
          //   }

          //   setQueryFilters((prev) => {
          //     return {
          //       ...prev,
          //       orderBy: query,
          //     };
          //   });
          // }}
          // onFilterModelChange={(ev) => {
          //   if (ev.quickFilterValues?.length !== 0) {
          //     const value = ev.quickFilterValues as string[];

          //     setSearchTerm((prev) => value[0]);
          //   } else {
          //     setSearchTerm((prev) => '');
          //   }
          // }}
          pageSizeOptions={[100]}
          checkboxSelection
          sx={{
            minHeight: '450px',
            height: '100%',
            border: 'none',
            width: '100%',
            marginTop: '5px',
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#defdf5',
              '&:hover': {
                backgroundColor: '#c6fbed',
              },
            },
            '& .MuiDataGrid-toolbarQuickFilter': {
              width: '100%',
              maxWidth: '400px',
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
};
