import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import { ButtonFormSettings } from '@/components/ui/button-form-settings';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface RegistrationEditProps {}

const FormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  role: z.number(),
  createdByName: z.string().optional(),
  modifiedByName: z.string().optional(),
});

const RegistrationEdit: React.FC<RegistrationEditProps> = () => {
  const navigate = useNavigate();
  let { registrationId } = useParams();
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);
  const [isLoading, setIsLoading] = useState(false);
  const [registrationInfo, setregistrationInfo] = useState<RegistrationModel>();

  const isAdmin = userProfile.roles.indexOf('Admin') !== -1;

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      createdByName: registrationInfo?.createdByName,
      modifiedByName: registrationInfo?.modifiedByName,
    },
  });

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/registrations/${registrationId}`)
        .then((response: AxiosResponse<RegistrationModel>) => {
          if (response.status === 200) {
            setregistrationInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...registrationInfo,
    });
  }, [registrationInfo]);

  const onSubmit = async () => {
    setIsLoading((prev) => !prev);
    await _api
      .get(`/api/registrations/${registrationInfo!.id}/approve`)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        toast({
          variant: 'default',
          title: 'Success',
          description: 'Registration approved',
          duration: 2000,
        });
      })
      .catch((error: AxiosError<ApiResponse<string>>) => {
        const response = error.response as AxiosResponse<ApiResponse<string>>;

        toast({
          variant: 'destructive',
          title: response.statusText,
          description: response.data.message,
          duration: 2000,
        });
      });

    setIsLoading((prev) => !prev);
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          {`${registrationInfo?.firstName} ${registrationInfo?.lastName}`}
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Registration{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex px-2 pt-5 pb-3">
                <Button
                  type="submit"
                  className="w-fit mr-2"
                  size="sm"
                  disabled={!isAdmin}
                >
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}{' '}
                  Approve
                </Button>
                <Button
                  variant="outline"
                  className="w-fit mr-2"
                  size="sm"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
                <ButtonFormSettings
                  createdByName={registrationInfo?.createdByName}
                  modifiedByName={registrationInfo?.modifiedByName}
                />
              </section>
              <FormSection title="Registration Information">
                <div className="grid grid-cols-1 gap-4 p-2">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          First Name<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Last Name<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Email<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Phone Number<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="role"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Role<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required disabled {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default RegistrationEdit;
