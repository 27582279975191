import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import { ButtonDeleteWithDialog } from '@/components/ui/button-delete-with-dialog';
import { ButtonFormSettings } from '@/components/ui/button-form-settings';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface FeedbackEditProps {}

const FormSchema = z.object({
  title: z.string(),
  description: z.string(),
  createdByName: z.string().optional(),
  modifiedByName: z.string().optional(),
});

const FeedbackEdit: React.FC<FeedbackEditProps> = () => {
  const navigate = useNavigate();
  let { feedbackId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState<FeedbackModel>();
  const { userProfile } = React.useContext(UserProfileCtx);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      createdByName: feedbackInfo?.createdByName,
      modifiedByName: feedbackInfo?.modifiedByName,
    },
  });

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/feedback/${feedbackId}`)
        .then((response: AxiosResponse<FeedbackModel>) => {
          if (response.status === 200) {
            setFeedbackInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...feedbackInfo,
    });
  }, [feedbackInfo]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .patch(`/api/feedback/${feedbackId}`, data)
      .then((response: AxiosResponse<ApiResponse<FeedbackModel>>) => {
        setIsLoading((prev) => !prev);
        console.log('response', response);

        if (response.status === 204) {
          // form.reset({}, { keepValues: true });
          setFeedbackInfo(data as FeedbackModel);

          toast({
            variant: 'default',
            title: 'Updated',
            description: 'Feedback saved successfully',
            duration: 2000,
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        toast({
          variant: 'destructive',
          title: 'Update Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          {feedbackInfo?.title}
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Feedback{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex justify-between pt-5 pb-3">
                <div className="px-2 flex items-center">
                  <Button
                    disabled={!form.formState.isDirty}
                    type="submit"
                    className="w-fit mr-2"
                    size="sm"
                  >
                    {isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}{' '}
                    Update
                  </Button>
                  <Button
                    variant="outline"
                    className="w-fit mr-2"
                    size="sm"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                  <ButtonFormSettings
                    createdByName={feedbackInfo?.createdByName}
                    modifiedByName={feedbackInfo?.modifiedByName}
                  />
                </div>
                <ButtonDeleteWithDialog
                  entity="feedback"
                  entityId={feedbackId}
                  hidden={userProfile.id !== feedbackInfo?.createdById}
                />
              </section>
              <FormSection title="Feedback Info">
                <div className="grid grid-cols-1 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Title<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Description<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Textarea required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default FeedbackEdit;
