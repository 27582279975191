import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface FeedbackCreateProps {}

const FormSchema = z.object({
  title: z.string().max(500),
  description: z.string().max(2500),
});

const FeedbackCreate: React.FC<FeedbackCreateProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .post('/api/feedback', data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        if (response.data.statusCode === 201) {
          setIsLoading((prev) => !prev);

          toast({
            variant: 'default',
            title: 'Submitted',
            description: 'Feedback submitted successfully',
            duration: 2000,
          });

          navigate(`/admin/feedback/${response.data.message}`);
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);
        toast({
          variant: 'destructive',
          title: 'Feedback Submission Failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          New Feedback
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Feedback{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex pl-2 pt-5 pb-3">
                <Button type="submit" className="w-fit mr-2">
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}{' '}
                  Create
                </Button>
                <Button
                  variant="outline"
                  className="w-fit mr-2"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </section>
              <FormSection title="Feedback Info">
                <div className="grid grid-cols-1 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Title<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Input required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Description<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Textarea required {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default FeedbackCreate;
