import { Button } from '@/components/ui/button';
import { CardAnnouncement } from '@/components/ui/card-announcement';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Icons } from '@/components/ui/icons';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTheme } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

interface AnnouncementListProps {}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0dce9c',
    },
  },
});

const FormSchema = z.object({
  title: z.string(),
  body: z.string(),
});

export const AnnouncementList: React.FC<AnnouncementListProps> = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: '',
      body: '',
    },
  });

  const refreshFeed = async (showLoading = true, query?: string) => {
    if (showLoading) {
      setIsLoading((prev) => !prev);
    }

    await _api
      .get(query ? query : '/api/announcements')
      .then((res: AxiosResponse) => {
        setAnnouncements((prev) => res.data);

        form.reset();

        if (showLoading) {
          setIsLoading((prev) => !prev);
        }
      })
      .catch((err: AxiosError) => {
        console.error('Could not fetch announcements', err.message);
      });
  };

  useEffect(() => {
    (async () => {
      refreshFeed();
    })();
  }, []);

  const submitAndClose = async (data: z.infer<typeof FormSchema>) => {
    await _api
      .post('/api/announcements', data)
      .then(async (res: AxiosResponse<ApiResponse<string>>) => {
        if (res.status === 201) {
          toast({
            variant: 'default',
            title: 'Submitted',
            description: 'Announcement created successfully',
            duration: 2000,
          });

          setOpenDialog((prev) => !prev);

          await refreshFeed();
        }
      })
      .catch((err: AxiosError) => {
        toast({
          variant: 'destructive',
          title: 'Failed',
          description: 'Failed to create post',
          duration: 2000,
        });

        console.error('Error posting announcement', err);
      });
  };

  const reorderByLikes = (arg: 'likes' | 'recent') => {
    let data: AnnouncementModel[] = [];

    if (arg === 'likes') {
      data = announcements.sort((a, b) => {
        return b.likes.length - a.likes.length;
      });
    }

    if (arg === 'recent') {
      data = announcements.sort((a, b) => {
        return (
          new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
        );
      });
    }

    setAnnouncements([...data]);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold tracking-tight pl-3">Announcements</h2>
      <div className="p-3 flex justify-between">
        <Dialog open={openDialog} onOpenChange={setOpenDialog}>
          <DialogTrigger asChild>
            <Button className="bg-[#0dce9c] hover:bg-[#67cfb4] text-black">
              <Icons.AddCircleSolid className="h-6 pr-2" /> Add new
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Add new announcement</DialogTitle>
              <DialogDescription>
                <p>
                  Make a post and let others know what's happening. To disable
                  notifications/mentions, update communication preferences in
                  your user profile
                </p>
              </DialogDescription>
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(submitAndClose)}>
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="pb-2">
                      <FormLabel>
                        Title<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="body"
                  render={({ field }) => (
                    <FormItem className="pb-2">
                      <FormLabel>
                        Body<span className="text-red-600">*</span>
                      </FormLabel>
                      <FormControl>
                        <Textarea required {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter className="flex justify-around">
                  <DialogClose asChild>
                    <Button type="button" variant="outline" className="mb-2">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button type="submit" className="mb-2">
                    Create
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
        <div className="text-sm content-end">
          Sort by:{' '}
          <span
            onClick={reorderByLikes.bind(null, 'likes')}
            className="hyperlink"
          >
            Top Liked
          </span>{' '}
          |{' '}
          <span
            onClick={reorderByLikes.bind(null, 'recent')}
            className="hyperlink"
          >
            Most Recent
          </span>
        </div>
      </div>

      {isLoading && (
        <div className="p-3">
          <Loader2 className="mr-2 h-8 w-8 animate-spin" />
        </div>
      )}

      <div className="min-h-[75vh] px-1">
        {announcements.map((item, index) => (
          <CardAnnouncement
            id={item.id}
            key={item.id}
            title={item.title}
            body={item.body}
            createdById={item.createdById}
            createdByName={item.createdByName}
            date={new Date(item.createdOn).toLocaleString()}
            openEditDialog={setOpenDialog}
            comments={item.comments}
            likes={item.likes}
            refreshFeed={refreshFeed}
          />
        ))}
      </div>
    </div>
  );
};

export default AnnouncementList;
