import axios from 'axios';
import Cookies from 'js-cookie';

export const _api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

_api.interceptors.request.use((config) => {
  const token = Cookies.get('.cbc.xsrf');
  if (token) {
    config.headers['X-XSRF-TOKEN'] = token;
  }
  return config;
});

_api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = `/login?message=Session expired`;
    }
    // } else if (error.response.status === 403 || error.response.status === 404) {
    //   window.location.href = '/unauthorized';
    // }

    return Promise.reject(error);
  },
);
