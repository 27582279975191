import { Menu } from '@/components/ui/menu';
import { Sidebar } from '@/components/ui/sidebar';

import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

const InteriorPageLayout = (
  <>
    <div key="cbc-interior-page" className="md:block">
      <Menu />
      <div className="grid grid-cols-12">
        <Sidebar className="lg:col-span-2 xl:col-span-2" />
        <div className="col-span-12 lg:col-span-10 xl:col-span-10">
          <main className="pt-2 pb-10">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  </>
) as ReactNode;

export default InteriorPageLayout;
