import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import React, { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast } from '@/components/ui/use-toast';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

interface RegistrationRequestFormProps {
  passThroughFormState: string | undefined;
}

const FormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  role: z.number({ message: 'role cannot be blank' }),
});

export const RegistrationRequestForm: React.FC<
  RegistrationRequestFormProps
> = ({ passThroughFormState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: 0,
    },
  });

  useEffect(() => {
    if (passThroughFormState) {
      form.setValue('email', passThroughFormState);
    }
  }, []);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .post('/api/registrations', data)
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        setIsLoading((prev) => !prev);

        toast({
          title: 'Request submitted successfully',
          description:
            'Please allow 24/hrs and check your SPAM folders for confirmation email',
          duration: 2000,
        });
      })
      .catch((error) => {
        setIsLoading((prev) => !prev);

        toast({
          variant: 'destructive',
          title: 'Request submission failed',
          description:
            'Request submission failed. Please try again or contact ty@cbccumming.org if issue continues',
          duration: 2000,
        });
        console.log(error);
      });
  };

  return (
    <Card className="max-w-[500px]">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold">Registration</CardTitle>
        <CardDescription>
          We need a little more info from you. Please complete the below:
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input required {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <Select
                    required
                    onValueChange={(field) => {
                      form.setValue('role', +field);
                    }}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1001">Deacon</SelectItem>
                      <SelectItem value="1000">Church Member</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit">
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{' '}
              Submit
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};
