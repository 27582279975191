import { Icons } from '@/components/ui/icons';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-[88%] text-center flex-col">
      <div>
        <Icons.Ex className="h-20" />
      </div>
      <div>
        <h1 className="text-2xl bold">Page not found</h1>
      </div>
    </div>
  );
};

export default NotFound;
