import { Icons } from '@/components/ui/icons';
import { _api } from '@/lib/api';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AxiosError, AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

const RouteHelper = {
  'meeting-minutes': '/deacons/meeting-minutes',
  events: '/common/events',
  activities: '/common/activities',
  feedback: '/admin/feedback',
};

const GridData = {
  event: {
    columns: [
      { field: 'id', headerName: 'id' },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 200,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/common/events/${params.row.id}`}>
              {params.row.title}
            </Link>
          );
        },
      },
      {
        field: 'startDate',
        headerName: 'Start Date',
        minWidth: 150,
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        minWidth: 200,
      },
      {
        field: 'location',
        headerName: 'Location',
        minWidth: 200,
        renderCell: (params: GridCellParams) => {
          return (
            <a
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
              href={`https://www.google.com/maps/place/${params.row.location}`}
              target="_blank"
              rel="noreferrer"
            >
              {params.row.location}
            </a>
          );
        },
      },
      { field: 'eventTypeCodeName', headerName: 'Event Type', minWidth: 150 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: EventModel) => {
      return {
        id: item.id,
        title: item.title,
        location: item.location,
        eventTypeCodeName: item.eventTypeCodeName,
        createdById: item.createdById,
        modifiedById: item.modifiedById,
        startDate:
          item.startDate && new Date(`${item.startDate}`).toLocaleString(),
        endDate: item.endDate && new Date(`${item.endDate}`).toLocaleString(),
        modifiedByName: item.modifiedByName,
        createdByName: item.createdByName,
      } as EventModel;
    },
  },
  eventAttendeeSubgrid: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'userName',
        headerName: 'Attendee Name',
        minWidth: 300,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/common/event-attendees/${params.row.id}`}
            >
              {params.row.userName}
            </Link>
          );
        },
      },
      {
        field: 'createdOn',
        headerName: 'Created On',
        minWidth: 200,
        renderCell: (params: GridCellParams) => {
          return new Date(params.row.createdOn).toLocaleString();
        },
      },
      {
        field: 'modifiedOn',
        headerName: 'Modified On',
        minWidth: 200,
        renderCell: (params: GridCellParams) => {
          return new Date(params.row.modifiedOn).toLocaleString();
        },
      },
    ],
    mapper: (item: EventAttendeeModel) => {
      return {
        id: item.id,
        eventId: item.eventId,
        eventTitle: item.eventTitle,
        userId: item.userId,
        userName: item.userName,
        createdOn: item.createdOn,
        modifiedOn: item.modifiedOn,
        createdById: item.createdById,
        modifiedById: item.modifiedById,
        createdByName: item.createdByName,
        modifiedByName: item.modifiedByName,
      } as EventAttendeeModel;
    },
  },
  contact: {
    columns: [
      { field: 'id', headerName: 'id' },
      {
        field: 'fullName',
        headerName: 'Full Name',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
              to={`/common/contacts/${params.id}`}
            >
              {params.row.fullName}
            </Link>
          );
        },
      },
      {
        field: 'phone1',
        headerName: 'Phone',
        minWidth: 150,
        renderCell: (params) => {
          return (
            <a
              href={`tel:${params.row.phone1}`}
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
            >
              {params.row.phone1}
            </a>
          );
        },
      },
      {
        field: 'address',
        headerName: 'Address',
        minWidth: 250,
        renderCell: (params: GridCellParams) => {
          return (
            <a
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
              href={`https://www.google.com/maps/place/${params.row.address}`}
              target="_blank"
              rel="noreferrer"
            >
              {params.row.address}
            </a>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <a
              href={`mailto:${params.row.email}`}
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
            >
              {params.row.email}
            </a>
          );
        },
      },
      { field: 'birthday', headerName: 'Birthday', minWidth: 250 },
      {
        field: 'lastActivityDate',
        headerName: 'Last Activity Date',
        minWidth: 250,
      },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: ContactModel) => {
      return {
        id: item.id,
        fullName: item.fullName,
        email: item.email,
        phone1: item.phone1,
        address: item.address,
        description: item.description,
        modifiedById: item.modifiedById,
        createdById: item.createdById,
        lastActivityDate:
          item.lastActivityDate &&
          new Date(`${item.lastActivityDate}`).toLocaleString(),
        modifiedByName: item.modifiedByName,
        createdByName: item.createdByName,
      } as ContactModel;
    },
  },
  user: {
    columns: [
      { field: 'id', headerName: 'id' },
      {
        field: 'fullName',
        headerName: 'Full Name',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/admin/users/${params.id}`}>
              {params.row.fullName}
            </Link>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <a
              href={`mailto:${params.row.email}`}
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
            >
              {params.row.email}
            </a>
          );
        },
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone Number',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <a className="hyperlink" href={`tel:${params.row.phoneNumber}`}>
              {params.row.phoneNumber}
            </a>
          );
        },
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        minWidth: 150,
      },
      { field: 'lastName', headerName: 'Last Name', minWidth: 200 },
      {
        field: 'lastLoginDate',
        headerName: 'Last Login Date',
        minWidth: 150,
      },
    ] as GridColDef[],
    mapper: (item: UserModel) => {
      return {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        fullName: item.fullName,
        email: item.email,
        phoneNumber: item.phoneNumber,
        lastLoginDate: new Date(item.lastLoginDate).toLocaleString(),
        announcementNotification: item.announcementNotification,
        mentionNotification: item.mentionNotification,
      } as UserModel;
    },
  },
  feedback: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/admin/feedback/${params.id}`}>
              {params.row.title}
            </Link>
          );
        },
      },
      { field: 'description', headerName: 'Description', minWidth: 400 },
      { field: 'createdOn', headerName: 'Created On', minWidth: 200 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: FeedbackModel) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        createdById: item.createdById,
        modifiedById: item.modifiedById,
        modifiedByName: item.modifiedByName,
        createdByName: item.createdByName,
        createdOn: new Date(item.createdOn).toLocaleString(),
      } as FeedbackModel;
    },
  },
  registration: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'fullName',
        headerName: 'Full Name',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/registrations/${params.id}`}
            >
              {params.row.fullName}
            </Link>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <a
              href={`mailto:${params.row.email}`}
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
            >
              {params.row.email}
            </a>
          );
        },
      },
      {
        field: 'phone',
        headerName: 'Phone Number',
        minWidth: 150,
        renderCell: (params) => {
          return (
            <a
              href={`tel:${params.row.phone}`}
              className="text-emerald-700 dark:text-emerald-800 hover:underline"
            >
              {params.row.phone}
            </a>
          );
        },
      },
      { field: 'role', headerName: 'Role', minWidth: 200 },
      {
        field: 'approved',
        headerName: 'Is Approved',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return params.value ? (
            <div className="flex">
              <Icons.CheckCircle className="text-green-700 w-5 mr-2" /> Approved
            </div>
          ) : (
            <div className="flex">
              <Icons.Clock className="w-5 mr-2" /> Waiting for approval
            </div>
          );
        },
      },
      { field: 'createdOn', headerName: 'Created On', minWidth: 200 },
    ] as GridColDef[],
    mapper: (item: RegistrationModel) => {
      return {
        id: item.id,
        fullName: `${item.firstName} ${item.lastName}`,
        email: item.email,
        phoneNumber: item.phoneNumber,
        approved: item.approved,
        role: item.role,
        createdOn: new Date(item.createdOn).toLocaleString(),
      } as RegistrationModel;
    },
  },
  documentMeetingMinutesSubgrid: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/deacons/documents/${params.id}`}>
              {params.row.title}
            </Link>
          );
        },
      },
      {
        field: 'download',
        headerName: 'Download',
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <div className="flex items-center justify-center h-full cursor-pointer">
              <Icons.CloudArrowDown
                onClick={async (e) => {
                  _api
                    .post('/api/documents/download/', {
                      documentName: params.row.documentKey,
                    })
                    .then(
                      (urlResponse: AxiosResponse<ApiResponse<FileDto>>) => {
                        const aElement = document.createElement('a');

                        aElement.href = urlResponse.data.message.preSignedUrl;
                        aElement.setAttribute(
                          'download',
                          urlResponse.data.message.fileName,
                        );
                        aElement.setAttribute('target', '_blank');
                        aElement.click();
                        aElement.remove();
                      },
                    )
                    .catch((error: AxiosError) => {
                      console.log('downloadError', error);
                    });
                }}
                className="h-6"
              />
            </div>
          );
        },
      },
      { field: 'description', headerName: 'Description', minWidth: 100 },

      { field: 'createdOn', headerName: 'Created On', minWidth: 200 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: DocumentModel) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        documentKey: item.documentKey,
        createdOn: new Date(item.createdOn).toLocaleString(),
        createdById: item.createdById,
        modifiedById: item.modifiedById,
        createdByName: item.createdByName,
        modifiedByName: item.modifiedByName,
      } as DocumentModel;
    },
  },
  document: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/deacons/documents/${params.id}`}>
              {params.row.title}
            </Link>
          );
        },
      },
      {
        field: 'download',
        headerName: 'Download',
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <div className="flex items-center justify-center h-full cursor-pointer">
              <Icons.CloudArrowDown
                onClick={async (e) => {
                  _api
                    .post('/api/documents/download/', {
                      documentName: params.row.documentKey,
                    })
                    .then(
                      (urlResponse: AxiosResponse<ApiResponse<FileDto>>) => {
                        const aElement = document.createElement('a');

                        aElement.href = urlResponse.data.message.preSignedUrl;
                        aElement.setAttribute(
                          'download',
                          urlResponse.data.message.fileName,
                        );
                        aElement.setAttribute('target', '_blank');
                        aElement.click();
                        aElement.remove();
                      },
                    )
                    .catch((error: AxiosError) => {
                      console.log('downloadError', error);
                    });
                }}
                className="h-6"
              />
            </div>
          );
        },
      },
      { field: 'description', headerName: 'Description', minWidth: 100 },
      {
        field: 'regardingMeetingMinutesId',
        headerName: 'Regarding',
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/deacons/meeting-minutes/${params.row.meetingMinutesId}`}
            >
              {params.row.meetingMinutesName}
            </Link>
          );
        },
      },

      { field: 'createdOn', headerName: 'Created On', minWidth: 200 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: DocumentModel) => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        documentKey: item.documentKey,
        createdOn: new Date(item.createdOn).toLocaleString(),
        createdById: item.createdById,
        meetingMinutesId: item.meetingMinutesId,
        meetingMinutesName: item.meetingMinutesName,
        modifiedById: item.modifiedById,
        createdByName: item.createdByName,
        modifiedByName: item.modifiedByName,
      } as DocumentModel;
    },
  },
  meetingMinutes: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 200,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/deacons/meeting-minutes/${params.id}`}
            >
              {params.row.title}
            </Link>
          );
        },
      },
      { field: 'date', headerName: 'Date', minWidth: 150 },
      { field: 'location', headerName: 'Location', minWidth: 200 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ],
    mapper: (item: MeetingMinutesModel) => {
      return {
        id: item.id,
        title: item.title,
        date: new Date(item.date).toLocaleString(),
        location: item.location,
        createdById: item.createdById,
        modifiedById: item.modifiedById,
        createdByName: item.createdByName,
        modifiedByName: item.modifiedByName,
      } as MeetingMinutesModel;
    },
  },
  activities: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'title',
        headerName: 'Title',
        width: 200,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/common/activities/${params.id}`}>
              {params.row.title}
            </Link>
          );
        },
      },
      { field: 'activityTypeCodeName', headerName: 'Activity Type' },
      { field: 'priorityCodeName', headerName: 'Priority' },
      {
        field: 'contactId',
        headerName: 'Regarding',
        width: 200,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/common/contacts/${params.row.contactId}`}
            >
              {params.row.contactName}
            </Link>
          );
        },
      },
      { field: 'description', headerName: 'Description', width: 300 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: ActivityModel) => {
      return {
        id: item.id,
        activityTypeCodeName: item.activityTypeCodeName,
        title: item.title,
        priorityCodeName: item.priorityCodeName,
        createdById: item.createdById,
        contactId: item.contactId,
        contactName: item.contactName,
        description: item.description,
        modifiedById: item.modifiedById,
        createdByName: item.createdByName,
        modifiedByName: item.modifiedByName,
      } as ActivityModel;
    },
  },
  activitiesContactSubgrid: {
    columns: [
      { field: 'id', headerName: 'Id' },
      {
        field: 'title',
        headerName: 'Title',
        width: 200,
        renderCell: (params: GridCellParams) => {
          return (
            <Link className="hyperlink" to={`/common/activities/${params.id}`}>
              {params.row.title}
            </Link>
          );
        },
      },
      { field: 'activityTypeCodeName', headerName: 'Activity Type' },
      { field: 'priorityCodeName', headerName: 'Priority' },
      { field: 'description', headerName: 'Description', width: 300 },
      {
        field: 'modifiedByName',
        headerName: 'Modified By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.modifiedById}`}
            >
              {params.row.modifiedByName}
            </Link>
          );
        },
      },
      {
        field: 'createdByName',
        headerName: 'Created By',
        sortable: false,
        minWidth: 150,
        renderCell: (params: GridCellParams) => {
          return (
            <Link
              className="hyperlink"
              to={`/admin/users/${params.row.createdById}`}
            >
              {params.row.createdByName}
            </Link>
          );
        },
      },
    ] as GridColDef[],
    mapper: (item: ActivityModel) => {
      return {
        id: item.id,
        activityTypeCodeName: item.activityTypeCodeName,
        title: item.title,
        priorityCodeName: item.priorityCodeName,
        createdById: item.createdById,
        description: item.description,
        modifiedById: item.modifiedById,
        createdByName: item.createdByName,
        modifiedByName: item.modifiedByName,
      } as ActivityModel;
    },
  },
};

export { GridData };
