import React from 'react';
import { Separator } from '@/components/ui/separator';

interface FormSectionProps {
  children: React.ReactNode;
  title: string;
  hidden?: boolean;
}

export const FormSection: React.FC<FormSectionProps> = ({
  children,
  title,
  hidden = false,
}) => {
  return (
    <section className="p-2" hidden={hidden}>
      <h3>{title}</h3>
      <Separator className="my-2" />
      {children}
    </section>
  );
};
