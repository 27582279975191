import { Icons } from '@/components/ui/icons';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[88%]">
      <Icons.Exclamation className="h-20" />

      <h1 className="text-2xl bold">Unauthorized or invalid permissions</h1>
      <p>
        Please{' '}
        <Link
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          to="/login"
        >
          log in
        </Link>{' '}
        or{' '}
        <Link
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          to="/registrations"
        >
          request access
        </Link>{' '}
        to the site
      </p>
    </div>
  );
};

export default Unauthorized;
