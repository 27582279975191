import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import { ButtonDeleteWithDialog } from '@/components/ui/button-delete-with-dialog';
import { ButtonFormSettings } from '@/components/ui/button-form-settings';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { FormSection } from '@/components/ui/form-section';
import { Icons } from '@/components/ui/icons';
import { Lookup } from '@/components/ui/lookup';
import { toast } from '@/components/ui/use-toast';
import { ViewCard, ViewCardContent } from '@/components/ui/view-card';
import { _api } from '@/lib/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError, AxiosResponse } from 'axios';
import { Loader2 } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

interface EventAttendeeEditProps {}

const FormSchema = z.object({
  eventId: z.number(),
  eventTitle: z.string(),
  userId: z.string(),
  userName: z.string(),
  createdByName: z.string().optional(),
  modifiedByName: z.string().optional(),
});

const EventAttendeeEdit: React.FC<EventAttendeeEditProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventAttendeeInfo, setEventAttendeeInfo] =
    useState<EventAttendeeModel>();
  const navigate = useNavigate();
  const { eventAttendeeId } = useParams();
  const { userProfile } = useContext(UserProfileCtx);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      eventId: eventAttendeeInfo?.eventId,
      eventTitle: eventAttendeeInfo?.eventTitle,
      userId: eventAttendeeInfo?.userId,
      userName: eventAttendeeInfo?.userName,
      createdByName: eventAttendeeInfo?.createdByName,
      modifiedByName: eventAttendeeInfo?.modifiedByName,
    },
  });

  useEffect(() => {
    (async () => {
      await _api
        .get(`/api/event-attendees/${eventAttendeeId}`)
        .then((response: AxiosResponse<EventAttendeeModel>) => {
          if (response.status === 200) {
            setEventAttendeeInfo(response.data);
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((error: AxiosError) => {
          toast({
            variant: 'destructive',
            title: 'Could not fetch data',
            description: error.message,
            duration: 2000,
          });
        });
    })();
  }, []);

  useEffect(() => {
    form.reset({
      ...eventAttendeeInfo,
    });
  }, [eventAttendeeInfo]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading((prev) => !prev);

    await _api
      .patch(`/api/event-attendees/${eventAttendeeId}`, {
        eventId: data.eventId,
        userId: data.userId,
      })
      .then((response: AxiosResponse<ApiResponse<string>>) => {
        if (response.status === 204) {
          setIsLoading((prev) => !prev);

          setEventAttendeeInfo(data as EventAttendeeModel);

          toast({
            variant: 'default',
            title: 'Updated',
            description: 'Event attendee updated successfully',
            duration: 2000,
          });
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        setIsLoading((prev) => !prev);

        toast({
          variant: 'destructive',
          title: 'Event attendee update failed',
          description: error.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className="w-full px-3 sm:px-4">
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold tracking-tight">
          {eventAttendeeInfo?.eventTitle} ({eventAttendeeInfo?.userName})
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icons.ArrowUTurnLeft className="h-6" />
          </Button>
        </h2>
        <p>
          Event Attendee{' '}
          {Object.keys(form.formState.dirtyFields).length > 0 && (
            <span className="italic text-sm font-normal">
              - Unsaved changes
            </span>
          )}
        </p>
      </div>
      <ViewCard>
        <ViewCardContent className="h-auto">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <section className="flex pt-5 pb-3 justify-between">
                <div className="px-2 flex items-center">
                  <Button
                    disabled={!form.formState.isDirty}
                    type="submit"
                    className="w-fit mr-2"
                    size="sm"
                  >
                    {isLoading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}{' '}
                    Update
                  </Button>
                  <Button
                    variant="outline"
                    className="w-fit mr-2"
                    size="sm"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                  <ButtonFormSettings
                    createdByName={eventAttendeeInfo?.createdByName}
                    modifiedByName={eventAttendeeInfo?.modifiedByName}
                  />
                </div>
                <ButtonDeleteWithDialog
                  entity="event-attendees"
                  entityId={eventAttendeeId}
                  onDeleteBehavior="back"
                  hidden={userProfile.id !== eventAttendeeInfo?.createdById}
                />
              </section>
              <FormSection title="Attendee Info">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                  <FormField
                    control={form.control}
                    name="eventTitle"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          Event<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Lookup
                            entity="events"
                            searchColumn="title"
                            disabled
                            defaultInputValue={eventAttendeeInfo?.eventTitle}
                            onValueChange={(params) => {
                              field.value = params.value;
                              form.setValue('eventTitle', params.value, {
                                shouldDirty: true,
                              });
                            }}
                            optionMapper={(item: EventModel) => {
                              return {
                                key: item.id,
                                label: item.title,
                                value: item.id,
                              };
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="userName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          User<span className="text-red-600">*</span>
                        </FormLabel>
                        <FormControl>
                          <Lookup
                            entity="users"
                            searchColumn="fullName"
                            defaultInputValue={eventAttendeeInfo?.userName}
                            onValueChange={(params) => {
                              field.value = params.value;
                              form.setValue('userId', params.value, {
                                shouldDirty: true,
                              });
                            }}
                            optionMapper={(item: UserModel) => {
                              return {
                                key: item.id,
                                label: item.fullName,
                                value: item.id,
                              };
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </FormSection>
            </form>
          </Form>
        </ViewCardContent>
      </ViewCard>
    </div>
  );
};

export default EventAttendeeEdit;
