import { UserProfileCtx } from '@/components/globals/context';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Icons } from '@/components/ui/icons';
import { _handlers } from '@/lib/utils';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { userProfile, setUserProfile } = useContext(UserProfileCtx);

  return (
    <div className="min-h-16 w-full px-[10px] text-white bg-zinc-900 flex items-center justify-between shadow-md">
      <Link
        className="flex items-center text-base sm:text-lg font-medium semi-bold "
        to="/"
      >
        <img
          src="https://cbc-deacon-portal-assets.s3.us-east-2.amazonaws.com/img/cbc_logo_light.png"
          alt="logo"
          className="mx-1 md:mx-2 w-4"
        />
        <p className="z-50 pl-2 font-medium semi-bold text-white-500 text-white">
          CBC - Deacon Portal
        </p>
      </Link>
      {userProfile.isAuthenticated && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="text-white">
              <Icons.UsersCircleSolid className="h-9" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <Link to={`/admin/users/${userProfile.id}`}>
              <DropdownMenuItem>
                <Icons.UserProfile className="w-5 mr-2" />
                <span>My profile</span>
              </DropdownMenuItem>
            </Link>
            <Link to="/admin/feedback/new">
              <DropdownMenuItem>
                <Icons.Pulse className="w-5 mr-2" />
                <span>Submit feedback</span>
              </DropdownMenuItem>
            </Link>
            <DropdownMenuItem onClick={_handlers.onClickLogout}>
              <Icons.Logout className="w-5 mr-2" />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};

export default Header;
